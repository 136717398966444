import React from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { ReactComponent as LoadingImg } from './loading.svg';
import ConnectedTextInput from './ConnectedTextInput';
import ConnectedDatePicker from './ConnectedDatePicker';
import ConnectedSelect from './ConnectedSelect';
import DB from './DB.js';
import SaveButton from './SaveButton';
import NavBar from './NavBar';

const Container = styled.div`
display: block;
`

const Row = styled.div`
clear: both;
width: 100%;
`

const LeftColumn = styled.div`
width: 50%;
padding: 0;
margin: 0;
float: left;
`

const RightColumn = styled.div`
width: 50%;
padding: 0;
margin: 0;
float: left;
`

class PatientFile extends React.Component {
  state = {
    loading: false
  }

  deletePatient = () => {
    const { patient, history } = this.props

    if (window.confirm(`Är du säker du vill radera patienten med personnummer ${patient.personnummer} ?`)) {
      this.setState({ loading: true }, () =>
          DB.deletePatient(patient.id)
            .then(() =>
                this.props.deletePatient(patient.id, () =>
                  history.push("/fidura-admin/")
                ))
            .catch(err => {
              console.log(err)
              this.setState({ loading: false })
            })
      )
    }
  }

  render() {
    const { patient, updatePatient } = this.props
    const { loading } = this.state

    if (loading) {
      return (<div></div>)
    } else {

    // Age calculation from personal number
    const bday = patient.personnummer.length === 12 ?
        patient.personnummer.substr(0, 8) :
        `19${patient.personnummer}`.substr(0, 8)
    const birthdate = new Date(
      bday.substr(0, 4),
      parseInt(bday.substr(4, 2)) - 1,
      bday.substr(6, 2))
    const age = patient.alder ? patient.alder :
      Math.abs(new Date(
        Date.now() - birthdate.getTime()
      ).getUTCFullYear() - 1970).toString()
    const gender = patient.personnummer && patient.personnummer.slice(-4) !== '0000' ?
        parseInt(patient.personnummer.charAt(patient.personnummer.length - 2)) % 2 === 0 ? 'Kvinna' : 'Man'
    : undefined


    return (
      <Container>
        <h1><Link to="/fidura-admin/">Patient . </Link>{patient.fornamn} {patient.efternamn}&nbsp;
        <span className="kodnummer">({patient.login} - {patient.kort_kodnummer})</span></h1>

        <NavBar id={patient.id} />

        <ConnectedTextInput
          textarea
          inline
          id={patient.id}
          name="extra_bevakning1_anteckning"
          label="Extra bevakning"
          value={patient.extra_bevakning1_anteckning}
          onSave={updatePatient}
        />

        <ConnectedDatePicker
          inline
          id={patient.id}
          name="extra_bevakning1"
          label=""
          date={patient.extra_bevakning1}
          onSave={updatePatient}
        />

        <hr />


        <Row>
          <LeftColumn>

            <ConnectedTextInput
              id={patient.id}
              personnummer
              name="personnummer"
              label="Personnummer"
              pattern="^[0-9]{10}$"
              title="Enter a valid personal number"
              value={patient.personnummer}
              onSave={updatePatient}
            />

            <ConnectedTextInput
              id={patient.id}
              name="fornamn"
              label="Förnamn"
              value={patient.fornamn}
              onSave={updatePatient}
            />

            <ConnectedTextInput
              id={patient.id}
              name="efternamn"
              label="Efternamn"
              value={patient.efternamn}
              onSave={updatePatient}
            />

            <ConnectedTextInput
              id={patient.id}
              name="kon"
              label="Kön"
              value={gender}
              disabled
            />

            <ConnectedTextInput
              id={patient.id}
              name="alder"
              label="Ålder vid inklusion"
              value={age}
              onSave={updatePatient}
            />

            <ConnectedTextInput
              id={patient.id}
              name="adress"
              label="Adress"
              value={patient.adress}
              onSave={updatePatient}
            />

            <ConnectedTextInput
              id={patient.id}
              name="postnummer"
              label="Postnummer"
              value={patient.postnummer}
              onSave={updatePatient}
            />

            <ConnectedTextInput
              id={patient.id}
              name="ort"
              label="Ort"
              value={patient.ort}
              onSave={updatePatient}
            />

            <ConnectedTextInput
              id={patient.id}
              name="telefon_mobil"
              label="Telefon mobil"
              value={patient.telefon_mobil}
              pattern="^((((0{2}?)|(\+){1})46)|0)7[\d]{8}$"
              onSave={updatePatient}
            />

            <ConnectedTextInput
              id={patient.id}
              name="telefon_bostad"
              label="Telefon bostad"
              pattern="^((((0{2}?)|(\+){1})46)|0)[\d]{8,9}$"
              value={patient.telefon_bostad}
              onSave={updatePatient}
            />

            <ConnectedTextInput
              id={patient.id}
              name="mejl"
              label="Mejl"
              pattern='^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
              value={patient.mejl}
              onSave={updatePatient}
            />

            <ConnectedSelect
              id={patient.id}
              name="region"
              label="Region"
              value={patient.region}
              onSave={updatePatient}
              options={[
                { id: 'goteborg', name: 'Göteborg'},
                { id: 'skane', name: 'Malmö/Lund/Kristianstad'},
                { id: 'helsingborg', name: 'Helsingborg'},
              ]}
              disabled={true}
              changeMsg={`Are you sure you want to change the patient's region?
                They will not appear in this database anymore,
                and instead be transfered to the region you will select`}
            />

            <Link to="/fidura-admin/"><button className="button marged">← Tillbaka</button></Link>
            <SaveButton />
            <button
              className="button marged delete"
              onClick={this.deletePatient}>Radera</button>
            { loading && <LoadingImg style={{height: "1.5em", width: "3em", verticalAlign: "middle"}} />}

          </LeftColumn>
          <RightColumn>
            <ConnectedTextInput
              textarea
              id={patient.id}
              name="kommentar"
              label="Kommentarsfält"
              value={patient.kommentar}
              onSave={updatePatient}
            />

            <ConnectedTextInput
              id={patient.id}
              name="login"
              label="Login"
              value={patient.login}
              onSave={updatePatient}
              readOnly
            />

            <ConnectedTextInput
              id={patient.id}
              name="password"
              label="Lösenord"
              value={patient.password}
              onSave={updatePatient}
              readOnly
            />

          </RightColumn>
        </Row>
      </Container>
    )
    }
  }
}

export default PatientFile;
