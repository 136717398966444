import React from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import ConnectedTextInput from './ConnectedTextInput';
import ConnectedDatePicker from './ConnectedDatePicker';
import ConnectedSelect from './ConnectedSelect';
import ConnectedCheckbox from './ConnectedCheckbox';
import ConnectedRadio from './ConnectedRadio';
import SaveButton from './SaveButton';
import NavBar from './NavBar';

const Container = styled.div`
display: block;
`

const Break = styled.div`
clear: both
`

class Inklusion extends React.Component {
  state = {
  }

  setPageLogic(patient) {
    let now = new Date()
    let date = (key) => new Date(patient[key])

    this.setState({
      telefonsamtal_a_date_reached:
        patient.telefonsamtal_a && date('telefonsamtal_a') < now,

      telefonsamtal_a_tackat: patient.telefonsamtal_a_tackat,

      telefonsamtal_b_date_reached:
        patient.telefonsamtal_b && date('telefonsamtal_b') < now,

      telefonsamtal_b_tackat: patient.telefonsamtal_b_tackat,

      samtycke: patient.samtycke
    })
  }

  componentDidMount() {
    this.setPageLogic(this.props.patient)
  }

  componentDidUpdate(prevProps) {
    if (this.props.patient !== prevProps.patient) {
      this.setPageLogic(this.props.patient)
    }
  }

  render() {
    const { patient, updatePatient, updatePandR } = this.props
    const {
      telefonsamtal_a_date_reached,
      telefonsamtal_a_tackat,
      telefonsamtal_b_date_reached,
      telefonsamtal_b_tackat,
      samtycke } = this.state

    const telefonsamtal_a_tackat_logic =
      telefonsamtal_a_date_reached

    const telefonsamtal_a_kommentar_logic =
      telefonsamtal_a_date_reached &&
      telefonsamtal_a_tackat === 'nej'

    const telefonsamtal_b_logic =
      telefonsamtal_a_date_reached &&
      telefonsamtal_a_tackat === 'vetEj'

    const telefonsamtal_b_tackat_logic =
      telefonsamtal_a_date_reached &&
      telefonsamtal_a_tackat === 'vetEj' &&
      telefonsamtal_b_date_reached

    const telefonsamtal_b_kommentar_logic =
      telefonsamtal_a_date_reached &&
      telefonsamtal_a_tackat === 'vetEj' &&
      telefonsamtal_b_date_reached &&
      telefonsamtal_b_tackat === 'nej'

    const intervention = patient.intervention ? patient.intervention :
      (patient.login.charAt(0) === '5' ?  'x' : 'y')

    return (
      <Container>
        <h1><Link to="/fidura-admin/">Patient . </Link>
          <Link to={`/fidura-admin/patient/${patient.id}`}>
            {patient.fornamn} {patient.efternamn}&nbsp;
            <span className="kodnummer">({patient.login} - {patient.kort_kodnummer})</span> .
        </Link></h1>

        <NavBar />

        <ConnectedTextInput
          id={patient.id}
          name="login"
          label="Kodnummer"
          value={patient.login}
          onSave={updatePatient}
          disabled
        />

        <ConnectedTextInput
          id={patient.id}
          name="kort_kodnummer"
          label="Kort kodnummer"
          value={patient.kort_kodnummer}
          onSave={updatePatient}
          pattern="^[0-9]{4}$"
        />

        <ConnectedDatePicker
          id={patient.id}
          name="brev1_skickat"
          label="Brev 1 skickat/lämnat"
          date={patient.brev1_skickat}
          onSave={updatePatient}
          kanInteAktuellt
        />

        <hr />

        <ConnectedDatePicker
          id={patient.id}
          name="excluderad"
          label="Patienten exkluderad"
          date={patient.excluderad}
          onSave={updatePatient}
        />

        { patient.excluderad &&
        <ConnectedRadio
          id={patient.id}
          name="excluderad_reason"
          label="Orsak"
          value={patient.excluderad_reason}
          options={[
            ['stomi', 'Stomi som hindrar deltagande'],
            ['palliativ_behandling', 'Palliativ behandling'],
            ['ingen_stralbehandling', 'Ingen/uppskjuten strålbehandling'],
            ['svar_svenska', 'Svårigheter svenska'],
            ['annan_orsak', 'Annan orsak'],
            ['ingen_kontakt', 'Ingen kontakt'],
          ]}
          onSave={updatePatient}
        />
        }

        { patient.excluderad &&
        <ConnectedTextInput
          textarea
          id={patient.id}
          name="excluderad_anteckning"
          label="Anteckning"
          value={patient.excluderad_anteckning}
          onSave={updatePatient}
        />
        }

        <ConnectedDatePicker
          id={patient.id}
          name="avbryter_studien"
          label="Patienten avbryter studien"
          date={patient.avbryter_studien}
          onSave={updatePatient}
        />

        { patient.avbryter_studien &&
        <ConnectedRadio
          id={patient.id}
          name="avbryter_studien_reason"
          label="Orsak"
          value={patient.avbryter_studien_reason}
          options={[
            ['andra_kosten', 'Vill ej ändra kosten'],
            ['kapslar', 'Svårigheter med kapslar'],
            ['orkar_inte', 'Orkar inte delta'],
            ['sociala_omstandigheter', 'Sociala omständigheter'],
            ['sjukhusinlaggning', 'Sjukhusinläggning'],
            ['inte_intresserad', 'Inte intresserad'],
            ['ingen_kontakt', 'Ingen kontakt'],
            ['annan_orsak', 'Annan orsak'],
            ['orsak_ej_angiven', 'Orsak ej angiven'],
          ]}
          onSave={updatePatient}
        />
        }

        { patient.avbryter_studien &&
        <ConnectedTextInput
          textarea
          id={patient.id}
          name="avbryter_studien_anteckning"
          label="Anteckning"
          value={patient.avbryter_studien_anteckning}
          onSave={updatePatient}
        />
        }

        <ConnectedCheckbox
          id={patient.id}
          name="avliden"
          label="Patienten är avliden"
          checked={patient.avliden}
          onSave={updatePatient}
        />

        <ConnectedDatePicker
          hidden={!patient.avliden}
          id={patient.id}
          name="avliden_datum"
          label="Datum"
          date={patient.avliden_datum}
          onSave={updatePatient}
        />


        <hr />

        <ConnectedDatePicker
          inline
          id={patient.id}
          name="telefonsamtal_a"
          label="Telefonsamtal A om studiedeltagande"
          date={patient.telefonsamtal_a}
          onSave={updatePandR}
          kanInteAktuellt
        />

        <ConnectedSelect
          inline
          hidden={!telefonsamtal_a_tackat_logic}
          id={patient.id}
          name="telefonsamtal_a_tackat"
          label="Tackat"
          value={telefonsamtal_a_tackat_logic?
            patient.telefonsamtal_a_tackat : ''}
          onSave={updatePatient}
          options={[
            { id: 'ja', name: 'Ja'},
            { id: 'nej', name: 'Nej'},
            { id: 'vetEj', name: 'Vet ej'},
          ]}
        />

        <Break />

        <ConnectedRadio
          hidden={!telefonsamtal_a_kommentar_logic}
          id={patient.id}
          name="telefonsamtal_a_reason"
          label="Orsak"
          value={patient.telefonsamtal_a_reason}
          options={[
            ['andra_kosten', 'Vill ej ändra kosten'],
            ['kapslar', 'Svårigheter med kapslar'],
            ['orkar_inte', 'Orkar inte delta'],
            ['sociala_omstandigheter', 'Sociala omständigheter'],
            ['sjukhusinlaggning', 'Sjukhusinläggning'],
            ['inte_intresserad', 'Inte intresserad'],
            ['ingen_kontakt', 'Ingen kontakt'],
            ['annan_orsak', 'Annan orsak'],
            ['orsak_ej_angiven', 'Orsak ej angiven'],
          ]}
          onSave={updatePatient}
        />

        <ConnectedTextInput
          inline
          hidden={!telefonsamtal_a_kommentar_logic}
          textarea
          id={patient.id}
          name="telefonsamtal_a_kommentar"
          label="Anteckning"
          value={ telefonsamtal_a_kommentar_logic ?
            patient.telefonsamtal_a_kommentar : '' }
          onSave={updatePatient}
        />

        <Break />

        <ConnectedDatePicker
          inline
          hidden={!telefonsamtal_b_logic}
          id={patient.id}
          name="telefonsamtal_b"
          label="Telefonsamtal B om studiedeltagande"
          date={ telefonsamtal_b_logic ?
            patient.telefonsamtal_b : undefined }
          onSave={updatePatient}
        />

        <ConnectedSelect
          inline
          hidden={!telefonsamtal_b_tackat_logic}
          id={patient.id}
          name="telefonsamtal_b_tackat"
          label="Tackat"
          value={ telefonsamtal_b_tackat_logic ?
            patient.telefonsamtal_b_tackat : '' }
          onSave={updatePatient}
          options={[
            { id: 'ja', name: 'Ja'},
            { id: 'nej', name: 'Nej'},
          ]}
        />

        <Break />

        <ConnectedRadio
          hidden={!telefonsamtal_b_kommentar_logic}
          id={patient.id}
          name="telefonsamtal_b_reason"
          label="Varför?"
          value={patient.telefonsamtal_b_reason}
          options={[
            ['andra_kosten', 'Vill ej ändra kosten'],
            ['kapslar', 'Svårigheter med kapslar'],
            ['orkar_inte', 'Orkar inte delta'],
            ['sociala_omstandigheter', 'Sociala omständigheter'],
            ['sjukhusinlaggning', 'Sjukhusinläggning'],
            ['inte_intresserad', 'Inte intresserad'],
            ['ingen_kontakt', 'Ingen kontakt'],
            ['annan_orsak', 'Annan orsak'],
            ['orsak_ej_angiven', 'Orsak ej angiven'],
          ]}
          onSave={updatePatient}
        />

        <ConnectedTextInput
          hidden={!telefonsamtal_b_kommentar_logic}
          textarea
          id={patient.id}
          name="telefonsamtal_b_kommentar"
          label="Anteckning"
          value={ telefonsamtal_b_kommentar_logic ?
            patient.telefonsamtal_b_kommentar : '' }
          onSave={updatePatient}
        />

        <hr />

        <ConnectedDatePicker
          id={patient.id}
          name="inklusionsmote"
          label="Inklusionsmöte"
          date={patient.inklusionsmote}
          onSave={updatePatient}
          OBS="Om du ändrar det här datumet måste du manuellt ändra datumet för <b>Enkät 1 planerat</b>, <b>Faecesprov 1 planerat</b>"
          timeSelect
          kanInteAktuellt
        />

        <hr />

        <ConnectedSelect
          id={patient.id}
          name="enkat"
          label="Enkät"
          value={patient.enkat}
          onSave={updatePatient}
          options={[
            { id: 'webb', name: 'Webb'},
            { id: 'papper', name: 'Papper'},
            { id: 'ejAktuellt', name: 'Ej aktuellt'},
          ]}
        />

        <ConnectedSelect
          id={patient.id}
          name="app"
          label="App"
          value={patient.app}
          onSave={updatePatient}
          options={[
            { id: 'webb', name: 'Webb'},
            { id: 'papper', name: 'Papper'},
            { id: 'ejAktuellt', name: 'Ej aktuellt'},
          ]}
        />

        <hr />

        <ConnectedDatePicker
          id={patient.id}
          name="faeceskit1_4_skickat"
          label="Faeceskit 1-4 + remiss skickat till patienten"
          date={patient.faeceskit1_4_skickat}
          onSave={updatePatient}
          kanInteAktuellt
        />

        <ConnectedDatePicker
          id={patient.id}
          name="faeceskit5_skickat"
          label="Faeceskit 5 + remiss skickat till patienten"
          date={patient.faeceskit5_skickat}
          onSave={updatePatient}
          kanInteAktuellt
        />

        <hr />

        <ConnectedSelect
          inline
          boolean
          id={patient.id}
          name="samtycke"
          label="Skriftligt samtycke lämnat"
          value={samtycke}
          onSave={updatePatient}
          options={[
            { id: 'ja', name: 'Ja'},
            { id: 'avbojer', name: 'Avböjer'},
          ]}
        />

        <ConnectedDatePicker
          inline
          hidden={!samtycke}
          id={patient.id}
          name="samtycke_lamnat"
          label=""
          date={samtycke ?
            patient.samtycke_lamnat : undefined }
          onSave={updatePatient}
        />

        <ConnectedSelect
          inline
          hidden={!samtycke}
          id={patient.id}
          name="samtycke_format"
          label="Format"
          value={samtycke ?
            patient.samtycke_format : '' }
          onSave={updatePatient}
          options={[
            { id: 'webb', name: 'Webb'},
            { id: 'papper', name: 'Papper'},
          ]}
        />

        <ConnectedTextInput
          inline
          textarea
          hidden={samtycke !== false}
          id={patient.id}
          name="samtycke_kommentar"
          label="Anteckning"
          value={patient.samtycke_kommentar}
          onSave={updatePatient}
        />

        <hr />

        <ConnectedSelect
          inline
          boolean
          id={patient.id}
          name="melior"
          label="Studiedeltagande inskrivit i Melior"
          value={patient.melior}
          onSave={updatePatient}
          options={[
            { id: 'ja', name: 'Ja'},
            { id: 'nej', name: 'Nej'},
          ]}
        />

        <ConnectedDatePicker
          inline
          hidden={!patient.melior}
          id={patient.id}
          name="melior_datum"
          label=""
          date={patient.melior_datum}
          onSave={updatePatient}
        />

        <hr />

        <ConnectedSelect
          id={patient.id}
          name="intervention"
          label="Intervention"
          value={intervention}
          onSave={updatePatient}
          options={[
            { id: 'x', name: 'X'},
            { id: 'y', name: 'Y'},
            { id: 'z', name: 'Z'},
            { id: 'forstudie', name: 'Förstudie'},
            { id: 'forstudiex', name: 'Förstudie X'},
            { id: 'forstudiey', name: 'Förstudie Y'},
            { id: 'forstudiez', name: 'Förstudie Z'},
          ]}
        />

        <Link to={`/fidura-admin/patient/${patient.id}`}>
          <button className="button marged">← Tillbaka</button>
        </Link>
        <SaveButton />

      </Container>
    )
  }
}

export default Inklusion;
