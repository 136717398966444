import React from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import ConnectedTextInput from './ConnectedTextInput';
import ConnectedDatePicker from './ConnectedDatePicker';
import ConnectedSelect from './ConnectedSelect';
import ConnectedCheckbox from './ConnectedCheckbox';
import SaveButton from './SaveButton';
import NavBar from './NavBar';

const Container = styled.div`
display: block;
`

const Row = styled.div`
clear: both;
width: 100%;
`

const LeftColumn = styled.div`
width: 50%;
padding: 0;
margin: 0;
float: left;
`

const RightColumn = styled.div`
width: 50%;
padding: 0;
margin: 0;
float: left;
`

class Prover extends React.Component {
  state = {
  }

  render() {
    const { patient, updatePatient } = this.props

    // Page logic
    let now = new Date()
    let date = (key) => new Date(patient[key])

    const blodprov1_utfort_date_reached = 
        patient.blodprov1_utfort && date('blodprov1_utfort') < now
    const blodprov2_utfort_date_reached =
        patient.blodprov2_utfort && date('blodprov2_utfort') < now
    const blodprov3_utfort_date_reached =
        patient.blodprov3_utfort && date('blodprov3_utfort') < now
    const blodprov4_utfort_date_reached =
        patient.blodprov4_utfort && date('blodprov4_utfort') < now

    const faecesprov1_postat = patient.faecesprov1_location === 'postat' || patient.faecesprov1_location === 'overlamnat'
    const faecesprov2_postat = patient.faecesprov2_location === 'postat' || patient.faecesprov2_location === 'overlamnat'
    const faecesprov3_postat = patient.faecesprov3_location === 'postat' || patient.faecesprov3_location === 'overlamnat'
    const faecesprov4_postat = patient.faecesprov4_location === 'postat' || patient.faecesprov4_location === 'overlamnat'
    const faecesprov5_postat = patient.faecesprov5_location === 'postat' || patient.faecesprov5_location === 'overlamnat'

    return (
      <Container>
        <h1><Link to="/fidura-admin/">Patient . </Link>
          <Link to={`/fidura-admin/patient/${patient.id}`}>
            {patient.fornamn} {patient.efternamn}&nbsp;
            <span className="kodnummer">({patient.login} - {patient.kort_kodnummer})</span> .
        </Link></h1>

        <NavBar />

        <Row>
          <LeftColumn>
            <ConnectedCheckbox
              id={patient.id}
              name="skicka_ej_blodprov_paminelser"
              label="Skicka ej sms-påminnelse blodprover"
              checked={patient.skicka_ej_blodprov_paminelser}
              onSave={updatePatient}
              long
            />

            <ConnectedDatePicker
              id={patient.id}
              name="blodprov1_planerat"
              label="Blodprov 1 (baseline) planerat"
              date={patient.blodprov1_planerat}
              onSave={updatePatient}
              note="Påminnelse en dag innan detta datum."
              kanInteAktuellt
            />

            <ConnectedDatePicker
              id={patient.id}
              name="blodprov1_bokat"
              label="Blodprov 1 (baseline) bokat"
              date={patient.blodprov1_bokat}
              onSave={updatePatient}
              kanInteAktuellt
            />

            <ConnectedDatePicker
              id={patient.id}
              name="blodprov1_utfort"
              label="Blodprov 1 (baseline) utfört"
              date={patient.blodprov1_utfort}
              onSave={updatePatient}
              kanInteAktuellt
            />

            <ConnectedTextInput
              hidden={!blodprov1_utfort_date_reached}
              textarea
              id={patient.id}
              name="blodprov1_kommentar"
              label="Anteckning"
              value={ blodprov1_utfort_date_reached ?
                patient.blodprov1_kommentar : '' }
              onSave={updatePatient}
            />

            <hr />

            <ConnectedDatePicker
              id={patient.id}
              name="blodprov2_planerat"
              label="Blodprov 2 (strål) planerat"
              date={patient.blodprov2_planerat}
              onSave={updatePatient}
              note="Påminnelse en dag innan detta datum."
              kanInteAktuellt
            />

            <ConnectedDatePicker
              id={patient.id}
              name="blodprov2_bokat"
              label="Blodprov 2 (strål) bokat"
              date={patient.blodprov2_bokat}
              onSave={updatePatient}
              kanInteAktuellt
            />

            <ConnectedDatePicker
              id={patient.id}
              name="blodprov2_utfort"
              label="Blodprov 2 (strål) utfört"
              date={patient.blodprov2_utfort}
              onSave={updatePatient}
              kanInteAktuellt
            />

            <ConnectedTextInput
              hidden={!blodprov2_utfort_date_reached}
              textarea
              id={patient.id}
              name="blodprov2_kommentar"
              label="Anteckning"
              value={ blodprov2_utfort_date_reached ?
                patient.blodprov2_kommentar : '' }
              onSave={updatePatient}
              kanInteAktuellt
            />

            <hr />

            <ConnectedDatePicker
              id={patient.id}
              name="blodprov3_planerat"
              label="Blodprov 3 (uppföljning kort) planerat"
              date={patient.blodprov3_planerat}
              onSave={updatePatient}
              note="Påminnelse en dag innan detta datum (fylls i automatiskt samma dag som Återbesök 1, Toxkontroll)."
              kanInteAktuellt
            />

            <ConnectedDatePicker
              id={patient.id}
              name="blodprov3_bokat"
              label="Blodprov 3 (uppföljning kort) bokat"
              date={patient.blodprov3_bokat}
              onSave={updatePatient}
              kanInteAktuellt
            />

            <ConnectedDatePicker
              id={patient.id}
              name="blodprov3_utfort"
              label="Blodprov 3 (uppföljning kort) utfört"
              date={patient.blodprov3_utfort}
              onSave={updatePatient}
              kanInteAktuellt
            />

            <ConnectedTextInput
              hidden={!blodprov3_utfort_date_reached}
              textarea
              id={patient.id}
              name="blodprov3_kommentar"
              label="Anteckning"
              value={ blodprov3_utfort_date_reached ?
                patient.blodprov3_kommentar : '' }
              onSave={updatePatient}
            />

            <hr />

            <ConnectedDatePicker
              id={patient.id}
              name="blodprov4_planerat"
              label="Blodprov 4 (uppföljning lång) planerat"
              date={patient.blodprov4_planerat}
              onSave={updatePatient}
              note="Påminnelse en dag innan detta datum (fylls i automatiskt samma dag som Återbesök 2)."
              kanInteAktuellt
            />

            <ConnectedDatePicker
              id={patient.id}
              name="blodprov4_bokat"
              label="Blodprov 4 (uppföljning lång) bokat"
              date={patient.blodprov4_bokat}
              onSave={updatePatient}
              kanInteAktuellt
            />

            <ConnectedDatePicker
              id={patient.id}
              name="blodprov4_utfort"
              label="Blodprov 4 (uppföljning lång) utfört"
              date={patient.blodprov4_utfort}
              onSave={updatePatient}
              kanInteAktuellt
            />

            <ConnectedTextInput
              hidden={!blodprov4_utfort_date_reached}
              textarea
              id={patient.id}
              name="blodprov4_kommentar"
              label="Anteckning"
              value={ blodprov4_utfort_date_reached ?
                patient.blodprov4_kommentar : '' }
              onSave={updatePatient}
            />
          </LeftColumn>
          <RightColumn>
            <ConnectedCheckbox
              id={patient.id}
              name="skicka_ej_faecesprov_paminelser"
              label="Skicka ej sms-påminnelse faecesprover"
              checked={patient.skicka_ej_faecesprov_paminelser}
              onSave={updatePatient}
              long
            />

            <ConnectedDatePicker
              id={patient.id}
              name="faecesprov1_planerat"
              label="Faecesprov 1 (baseline) planerat"
              date={patient.faecesprov1_planerat}
              onSave={updatePatient}
              note="Påminnelse detta datum (fylls i automatiskt en dag efter Inklusionsmöte)."
              kanInteAktuellt
            />

            <ConnectedDatePicker
              id={patient.id}
              name="faecesprov1_utfort"
              label="Faecesprov 1 (baseline) utfört"
              date={patient.faecesprov1_utfort}
              onSave={updatePatient}
              timeSelect
              kanInteAktuellt
            />

            <ConnectedDatePicker
              id={patient.id}
              name="faecesprov1_infryst_patient"
              label="Faecesprov 1 (baseline) infryst patient"
              date={patient.faecesprov1_infryst_patient}
              onSave={updatePatient}
              timeSelect
              kanInteAktuellt
            />

            <ConnectedDatePicker
              id={patient.id}
              name="faecesprov1_infryst"
              label="Faecesprov 1 (baseline) infryst labb"
              date={patient.faecesprov1_infryst}
              onSave={updatePatient}
              kanInteAktuellt
            />

            <ConnectedSelect
              id={patient.id}
              name="faecesprov1_location"
              label="Faecesprov 1 levererat"
              value={patient.faecesprov1_location ?
                patient.faecesprov1_location : ''}
              onSave={updatePatient}
              options={[
                { id: 'postat', name: 'Postat'},
                { id: 'overlamnat', name: 'Överlämnat'},
                { id: 'inteAktuellt', name: 'Inte aktuellt'},
              ]}
            />

            <ConnectedTextInput
              textarea
              hidden={!faecesprov1_postat}
              id={patient.id}
              name="faecesprov1_location_note"
              label="Anteckning"
              value={patient.faecesprov1_location_note}
              onSave={updatePatient}
            />

            <hr />

            <ConnectedDatePicker
              id={patient.id}
              name="faecesprov2_planerat"
              label="Faecesprov 2 (strålstart) planerat"
              date={patient.faecesprov2_planerat}
              onSave={updatePatient}
              note="Påminnelse detta datum (fylls i automatiskt två dagar före Strålstart)."
              kanInteAktuellt
            />

            <ConnectedDatePicker
              id={patient.id}
              name="faecesprov2_utfort"
              label="Faecesprov 2 (strålstart) utfört"
              date={patient.faecesprov2_utfort}
              onSave={updatePatient}
              timeSelect
              kanInteAktuellt
            />

            <ConnectedDatePicker
              id={patient.id}
              name="faecesprov2_infryst_patient"
              label="Faecesprov 2 (strålstart) infryst patient"
              date={patient.faecesprov2_infryst_patient}
              onSave={updatePatient}
              timeSelect
              kanInteAktuellt
            />

            <ConnectedDatePicker
              id={patient.id}
              name="faecesprov2_infryst"
              label="Faecesprov 2 (strålstart) infryst labb"
              date={patient.faecesprov2_infryst}
              onSave={updatePatient}
              kanInteAktuellt
            />

            <ConnectedSelect
              id={patient.id}
              name="faecesprov2_location"
              label="Faecesprov 2 levererat"
              value={patient.faecesprov2_location ?
                patient.faecesprov2_location : ''}
              onSave={updatePatient}
              options={[
                { id: 'postat', name: 'Postat'},
                { id: 'overlamnat', name: 'Överlämnat'},
                { id: 'inteAktuellt', name: 'Inte aktuellt'},
              ]}
            />

            <ConnectedTextInput
              textarea
              hidden={!faecesprov2_postat}
              id={patient.id}
              name="faecesprov2_location_note"
              label="Anteckning"
              value={patient.faecesprov2_location_note}
              onSave={updatePatient}
            />

            <hr />

            <ConnectedDatePicker
              id={patient.id}
              name="faecesprov3_planerat"
              label="Faecesprov 3 (strålslut) planerat"
              date={patient.faecesprov3_planerat}
              onSave={updatePatient}
              note="Påminnelse detta datum (fylls i automatiskt tre dagar före Planerat strålslut)."
              kanInteAktuellt
            />

            <ConnectedDatePicker
              id={patient.id}
              name="faecesprov3_utfort"
              label="Faecesprov 3 (strålslut) utfört"
              date={patient.faecesprov3_utfort}
              onSave={updatePatient}
              timeSelect
              kanInteAktuellt
            />

            <ConnectedDatePicker
              id={patient.id}
              name="faecesprov3_infryst_patient"
              label="Faecesprov 3 (strålslut) infryst patient"
              date={patient.faecesprov3_infryst_patient}
              onSave={updatePatient}
              timeSelect
              kanInteAktuellt
            />

            <ConnectedDatePicker
              id={patient.id}
              name="faecesprov3_infryst"
              label="Faecesprov 3 (strålslut) infryst labb"
              date={patient.faecesprov3_infryst}
              onSave={updatePatient}
              kanInteAktuellt
            />

            <ConnectedSelect
              id={patient.id}
              name="faecesprov3_location"
              label="Faecesprov 3 levererat"
              value={patient.faecesprov3_location ?
                patient.faecesprov3_location : ''}
              onSave={updatePatient}
              options={[
                { id: 'postat', name: 'Postat'},
                { id: 'overlamnat', name: 'Överlämnat'},
                { id: 'inteAktuellt', name: 'Inte aktuellt'},
              ]}
            />

            <ConnectedTextInput
              textarea
              hidden={!faecesprov3_postat}
              id={patient.id}
              name="faecesprov3_location_note"
              label="Anteckning"
              value={patient.faecesprov3_location_note}
              onSave={updatePatient}
            />

            <hr />

            <ConnectedDatePicker
              id={patient.id}
              name="faecesprov4_planerat"
              label="Faecesprov 4 (uppföljning kort) planerat"
              date={patient.faecesprov4_planerat}
              onSave={updatePatient}
              note="Påminnelse detta datum (fylls i automatiskt fyra dagar innan Återbesök 1 (Toxkontroll) eller 4 veckor efter Planerat strålslut)"
              kanInteAktuellt
            />

            <ConnectedDatePicker
              id={patient.id}
              name="faecesprov4_utfort"
              label="Faecesprov 4 (uppföljning kort) utfört"
              date={patient.faecesprov4_utfort}
              onSave={updatePatient}
              timeSelect
              kanInteAktuellt
            />

            <ConnectedDatePicker
              id={patient.id}
              name="faecesprov4_infryst_patient"
              label="Faecesprov 4 (uppföljning kort) infryst patient"
              date={patient.faecesprov4_infryst_patient}
              onSave={updatePatient}
              timeSelect
              kanInteAktuellt
            />

            <ConnectedDatePicker
              id={patient.id}
              name="faecesprov4_infryst"
              label="Faecesprov 4 (uppföljning kort) infryst labb"
              date={patient.faecesprov4_infryst}
              onSave={updatePatient}
              kanInteAktuellt
            />

            <ConnectedSelect
              id={patient.id}
              name="faecesprov4_location"
              label="Faecesprov 4 levererat"
              value={patient.faecesprov4_location ?
                patient.faecesprov4_location : ''}
              onSave={updatePatient}
              options={[
                { id: 'postat', name: 'Postat'},
                { id: 'overlamnat', name: 'Överlämnat'},
                { id: 'inteAktuellt', name: 'Inte aktuellt'},
              ]}
            />

            <ConnectedTextInput
              textarea
              hidden={!faecesprov4_postat}
              id={patient.id}
              name="faecesprov4_location_note"
              label="Anteckning"
              value={patient.faecesprov4_location_note}
              onSave={updatePatient}
            />

            <hr />

            <ConnectedDatePicker
              id={patient.id}
              name="faecesprov5_planerat"
              label="Faecesprov 5 (uppföljning lång) planerat"
              date={patient.faecesprov5_planerat}
              onSave={updatePatient}
              note="Påminnelse detta datum (fylls i automatiskt fyra dagar innan Återbesök 2)."
              kanInteAktuellt
            />

            <ConnectedDatePicker
              id={patient.id}
              name="faecesprov5_utfort"
              label="Faecesprov 5 (uppföljning lång) utfört"
              date={patient.faecesprov5_utfort}
              onSave={updatePatient}
              timeSelect
              kanInteAktuellt
            />

            <ConnectedDatePicker
              id={patient.id}
              name="faecesprov5_infryst_patient"
              label="Faecesprov 5 (uppföljning lång) infryst patient"
              date={patient.faecesprov5_infryst_patient}
              onSave={updatePatient}
              timeSelect
              kanInteAktuellt
            />

            <ConnectedDatePicker
              id={patient.id}
              name="faecesprov5_infryst"
              label="Faecesprov 5 (uppföljning lång) infryst labb"
              date={patient.faecesprov5_infryst}
              onSave={updatePatient}
              kanInteAktuellt
            />

            <ConnectedSelect
              id={patient.id}
              name="faecesprov5_location"
              label="Faecesprov 5 levererat"
              value={patient.faecesprov5_location ?
                patient.faecesprov5_location : ''}
              onSave={updatePatient}
              options={[
                { id: 'postat', name: 'Postat'},
                { id: 'overlamnat', name: 'Överlämnat'},
                { id: 'inteAktuellt', name: 'Inte aktuellt'},
              ]}
            />

            <ConnectedTextInput
              textarea
              hidden={!faecesprov5_postat}
              id={patient.id}
              name="faecesprov5_location_note"
              label="Anteckning"
              value={patient.faecesprov5_location_note}
              onSave={updatePatient}
            />

          </RightColumn>
        </Row>

        <Link to={`/fidura-admin/patient/${patient.id}`}>
          <button className="button marged">← Tillbaka</button>
        </Link>
        <SaveButton />

      </Container>
    )
  }
}

export default Prover;
