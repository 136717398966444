import React from 'react';
import styled from 'styled-components';
import { ReactComponent as LoadingImg } from './loading.svg';
import DB from './DB.js';

const Container = styled.div`
display: block;
clear: both;
padding: 1em;
`

const Saved = styled.span`
color: green;
padding-left: 1em;

&.shown {
  visibility: visible;
  opacity: 1;
  transition: opacity 0s linear;
}

&.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
}
`

const Label = styled.label`
display: inline-block;
width: 150px;
text-align: right;
vertical-align: middle;
`

const Input = styled.input`
font-size: 1.1em;
vertical-align: middle;
`

class ConnectedCheckbox extends React.Component {
  state = {
    checked: false,
    loading: false,
    savedMessage: false
  }

  componentDidUpdate(prevProps) {
    const { checked, id, name, onSave } = this.props

    if (checked !== prevProps.checked) {
      this.setState({ checked: checked }, () => {
        if (prevProps.checked) {
          onSave(id, name, checked)
        }
      })
    }
  }

  componentDidMount() {
    this.setState({
      checked: this.props.checked
    })
  }

  onChange = (e) => {
    const checked = e.target.checked
    const { id, name, onSave } = this.props

    this.setState({ checked: checked, loading: true }, () =>
      DB.saveChanges(id, { [name]: checked })
        .then((pat) =>
          this.setState({
            loading: false,
            savedMessage: checked !== undefined
          }, () => {
            onSave(pat, () =>
              setTimeout(() => {
                this.setState({savedMessage: false})
              }, 1000)
            )
          })
        )
        .catch(err => console.log(err))
    )
    
  }

  render() {
    const { label, name, title, autoFocus, disabled, hidden, long, inline } = this.props
    const { checked, loading, savedMessage } = this.state
    const hash = window.location.hash.substr(1)
    const containerClasses = (hash === `anchor-${name}`) ? "highlighted" : ""

    const labelStyle = {
      width: inline && label === "" ? '0' : long ? '300px': '150px'
    }

    let classes = ""
    if (savedMessage) classes += " valid"
    if (loading) classes += " loading"

    const display = hidden ? 'none' : inline ? 'inline-block' : 'block'
    const paddingLeft = inline && label === "" ? '0' : '1em'
    const paddingRight = inline && label !== "" ? '0' : '1em'

    return (
      <Container
        style={{display, paddingLeft, paddingRight}}
        className={containerClasses}
      >
        <Label style={labelStyle}>{label}</Label>
        <Input
          type="checkbox"
          checked={checked || false}
          onChange={this.onChange}
          title={title}
          autoFocus={autoFocus}
          disabled={disabled}
          className={classes}
        />

        { loading && (
          <LoadingImg style={{height: "1.5em", width: "3em", verticalAlign: "middle"}}/>
        )}

        <Saved className={ savedMessage ? "shown" : "hidden" }>Sparats</Saved>

      </Container>
    )
  }
}

export default ConnectedCheckbox;
