import React from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import Filters from './Filters';
import Columns, { AllColumns } from './Columns';
import { format } from 'date-fns';
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

//const SORTED_BY_NAME = ['name', 'email', 'phone', 'address', 'password']
const SORTED_BY_VALUE = ['personnummer', 'login']

const BOOLEAN_FIELDS = [
  "samtycke",
  "melior",
  "behandling_fore_enkat3",
  "behandling_fore_enkat3_cellgifter",
  "behandling_fore_enkat3_annat",
]

const SPECIAL_FILTERS = [
  'Inkluderade patienter',
  'Pågående strålbehandling',
  'Nya patienter',
  'Har ett kort kodnummer',
  'Kvinnor',
  'Män',
]

const DATE_FIELDS = [
    "extra_bevakning1",
    "brev1_skickat",
    "excluderad",
    "avbryter_studien",
    "avliden_datum",
    "telefonsamtal_a",
    "telefonsamtal_b",
    "inklusionsmote",
    "faeceskit1_4_skickat",
    "faeceskit5_skickat",
    "samtycke_lamnat",
    "melior_datum",
    "mottagit_forpackningar1",
    "mottagit_forpackningar2",
    "mottagit_forpackningar3",
    "mottagit_forpackningar4",
    "mottagit_forpackningar5",
    "nybesok",
    "forberedande_besok",
    "stralstart",
    "planerat_stralslut",
    "stralslut",
    "blodprov1_planerat",
    "blodprov1_bokat",
    "blodprov1_utfort",
    "faecesprov1_planerat",
    "faecesprov1_utfort",
    "faecesprov1_infryst",
    "faecesprov1_infryst_patient",
    "faecesprov2_planerat",
    "faecesprov2_utfort",
    "faecesprov2_infryst",
    "faecesprov2_infryst_patient",
    "blodprov2_planerat",
    "blodprov2_bokat",
    "blodprov2_utfort",
    "blodprov3_planerat",
    "blodprov3_bokat",
    "blodprov3_utfort",
    "faecesprov3_planerat",
    "faecesprov3_utfort",
    "faecesprov3_infryst",
    "faecesprov3_infryst_patient",
    "faecesprov4_planerat",
    "faecesprov4_utfort",
    "faecesprov4_infryst",
    "faecesprov4_infryst_patient",
    "blodprov4_planerat",
    "blodprov4_bokat",
    "blodprov4_utfort",
    "faecesprov5_planerat",
    "faecesprov5_utfort",
    "faecesprov5_infryst",
    "faecesprov5_infryst_patient",
    "enkat1_planerat",
    "enkat1_fyllt",
    "enkat1_kontrollerat",
    "enkat2_planerat",
    "enkat2_fyllt",
    "enkat2_kontrollerat",
    "enkat3_planerat",
    "enkat3_fyllt",
    "enkat3_kontrollerat",
    "aterbesok1",
    "aterbesok2",
    "telefonuppfoljning1",
    "telefonuppfoljning2",
    "telefonuppfoljning3",
    "telefonuppfoljning4",
    "extra_telefonuppfoljning",
    "extra_telefonuppfoljning2",
    "extra_telefonuppfoljning3",
    "extra_telefonuppfoljning4",
    "extra_telefonuppfoljning5",
    "mottagit_ater_studiepulver",
    "rapporterat_aterstaende_kapslar",
    "kost",
    "toabesok_med_avforing",
    "mediciner",
    "lackage",
    "gaser",
    "kapslar",
    "start_kapslar_datum",
    "avslut_kapslar_datum",
]

const SearchField = styled.input`
border-radius: 1rem !important;
font-size: 1.1rem;
width: 30%;
min-width: 300px;
margin: 0.5rem 0 1rem 0.7rem;
vertical-align: top;
 
&:focus {
  outline: none;
  border-color: #aaa;
}

&:focus::placeholder {
  color: #ccc;
}
`

const TableContainer = styled.div`
display: block;
width: 100%;
`

const Clear = styled.div`
clear: both;
height: 10px;
`

const FiltersContainer = styled.div`
display: block;
width: 100%;
padding-left: 1em;
vertical-align: top;
margin-top: 1em;
`

const Table = styled.table`
margin: auto;
width: 100%;
overflow-x: scroll;
min-width: 350px;

& td {
  padding: 0.4em;
  max-width: 200px;
}

& thead th {
  font-size: 1.5em;
  font-weight: 300;
  text-align: left;
  padding-bottom: 0.5em;
}

& tbody tr {
  text-decoration: none;
  display: table-row;
  cursor: pointer;
}

& tbody tr:hover {
  color: blue;
}

& tbody tr:nth-child(odd) {
  background-color: #f4f4f4;
}
`

const TH = styled.th`
line-height: 0.7em;

& button {
  font-weight: bold;
  max-width: 11em;
}

& buttoon {
  border: none;
  background: white;
  color: #333;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  cursor: pointer;
}

& button:hover,
& button:active,
& button:focus {
  color: blue;
}
`

const CloseColumnButton =  styled.button`
`

const PatientCounter = styled.div`
display: block;
float: left;
padding-top: 1em;
padding-left: 1em;
font-weight: bold;
`

const PatientExport = styled.div`
display: block;
float: left;
padding-top: 1em;
padding-left: 1em;
font-weight: bold;
`

const Registrera = styled.div`
display: block;
margin-top: -2em;
`

function TableHeadCell({ onClick, title, checked, order, custom, deleteColumn }) {
  return (
    <TH>
      <button onClick={() => onClick()}>
        {title}

        { checked && order==="asc" && (
          <span>&nbsp;↑</span>
        )}
        { checked && order==="desc" && (
          <span>&nbsp;↓</span>
        )}
      </button>
      {custom && <CloseColumnButton onClick={deleteColumn}>&times;</CloseColumnButton>}
    </TH>
  )
}

class PatientTable extends React.Component {
  state = {
    sort: '',
    order: 'desc',
    search: '',
    filters: [],
    extraColumns: []
  }

  // Save and load state from localStorage
  componentDidMount = () => {
    const filters = JSON.parse(localStorage.getItem('filters')) || []
    const extraColumns = JSON.parse(localStorage.getItem('extraColumns')) || []
    this.setState({
      filters: filters.map(
        f => f.date ? {...f, value: new Date(f.value)}: f
      ),
      extraColumns: extraColumns
    })
  }
 
  componentWillUnmount = () => {
    localStorage.setItem('filters', JSON.stringify(this.state.filters))
    localStorage.setItem('extraColumns', JSON.stringify(this.state.extraColumns))
  }

  sortBy = (key) => {
    let order = (this.state.sort === key && this.state.order === 'desc') ? 'asc' : 'desc'

    this.setState({
      sort: key,
      order: order
    })
  }

  onSearchChange = (e) => {
    this.setState({
      search: e.target.value
    })
  }

  onSearchEnter = (e, patientsRows) => {
    if(e.which === 13 && patientsRows.length === 1) {
      this.props.history.push(`/fidura-admin/patient/${patientsRows[0].key}`)
    }
  }

  onFilterUpdate = (newFilters, callback) => {
    this.setState({ filters: newFilters }, callback)
  }

  addColumn = (column, callback) => {
    return this.state.extraColumns.find(c => c.key === column.key) ?
      callback() :
      this.setState({ extraColumns: [...this.state.extraColumns, column] }, callback)
  }

  deleteColumn = (column, callback) => {
    const newColumns = this.state.extraColumns.filter(c => c.key !== column)
    this.setState({ extraColumns: newColumns }, callback)
  }

  onRowClicked = (patientId) => {
    this.props.history.push(`/fidura-admin/patient/${patientId}`)
  }

  passSpecialFilter = (patient, filter) => {
    const now = new Date()

    switch (filter.title) {
      case 'Inkluderade patienter':
        return !patient.excluderad && !patient.avbryter_studien &&
          patient.samtycke

      case 'Pågående strålbehandling':
        return patient.stralstart && new Date(patient.stralstart) < now &&
          patient.planerat_stralslut && new Date(patient.planerat_stralslut) > now

      case 'Nya patienter':
        return (patient.samtycke === undefined || patient.samtycke === null) &&
          !patient.telefonsamtal_a &&
          !patient.excluderad && !patient.avbryter_studien && !patient.avliden

      case 'Har ett kort kodnummer':
        return patient.kort_kodnummer

      case 'Kvinnor':
        return patient.personnummer &&
          patient.personnummer.slice(-4) !== '0000' &&
          parseInt(patient.personnummer.charAt(patient.personnummer.length - 2)) % 2 === 0

      case 'Män':
        return patient.personnummer &&
          patient.personnummer.slice(-4) !== '0000' &&
          parseInt(patient.personnummer.charAt(patient.personnummer.length - 2)) % 2 !== 0

      default:
        return false
    }

  }

  render() {
    const { patients } = this.props
    const { search, sort, order, filters, extraColumns } = this.state

    const sortPatients = (a, b) => {
      if (sort === '') {
        return 0
      }

      if (sort === 'kon') {
        const konA = a.personnummer && a.personnummer.slice(-4) !== '0000' ?
            parseInt(a.personnummer.charAt(a.personnummer.length - 2)) % 2 === 0 ? 'K' : 'M'
        : undefined

        const konB = b.personnummer && b.personnummer.slice(-4) !== '0000' ?
            parseInt(b.personnummer.charAt(b.personnummer.length - 2)) % 2 === 0 ? 'K' : 'M'
        : undefined

        return (order === 'desc') ? konA.localeCompare(konB) : konB.localeCompare(konA)

      } else if (SORTED_BY_VALUE.includes(sort)) {
        return (order === 'desc') ? a[sort] - b[sort] : - (a[sort] - b[sort])

      } else if(BOOLEAN_FIELDS.includes(sort)) {
        if (a[sort] === b[sort]) {
          return 0
        } else if (a[sort] === true) {
          return (order === 'desc') ? -1 : 1
        } else if (a[sort] === false && b[sort] === true) {
          return (order === 'desc') ? 1 : -1
        } else if (a[sort] === false && b[sort] === null)  {
          return (order === 'desc') ? -1 : 1
        } else {
          return (order === 'desc') ? 1 : -1
        }

      } else if(DATE_FIELDS.includes(sort)) {
        return (order === 'desc') ?
          new Date(a[sort]) - new Date(b[sort]) :
          - (new Date(a[sort]) - new Date(b[sort]))

      } else {
        var nameA = a[sort] ? a[sort].toUpperCase() : ''; // ignore upper and lowercase
        var nameB = b[sort] ? b[sort].toUpperCase() : ''; // ignore upper and lowercase
        if ((nameA < nameB && order === 'desc') || (nameA > nameB && order === 'asc')) {
          return -1
        }
        if ((nameA > nameB && order === 'desc') || (nameA < nameB && order === 'asc')) {
          return 1
        }

        // names must be equal
        return 0
      }
    }

    const searchPatients = (patient => {
      // Only patients matching search string
      if (search === '') return true

      let searchRegEx = new RegExp(search, 'i')

      for (var key in patient) {
        if (patient[key] && searchRegEx.test(patient[key].toString())) return true
      }

      if (patient.efternamn && patient.fornamn) {
        const name1 = `${patient.fornamn.trim()} ${patient.efternamn.trim()}`
        const name2 = `${patient.efternamn.trim()} ${patient.fornamn.trim()}`

        if (searchRegEx.test(name1) || searchRegEx.test(name2)) return true
      }

      const searchTrimmed = search.trim()

      if (searchTrimmed.startsWith('19') && searchTrimmed.length > 2) {
        let personnummerRegEx = new RegExp(searchTrimmed.substr(2, searchTrimmed.length))

        return patient.personnummer && personnummerRegEx.test(patient.personnummer.toString())
      }

      return false
    })

    const filterPatients = (patient => {
      // Only patient matching filters
      return !filters || filters.length === 0 || filters.reduce((prevFiltersPassed, f) => {
         if (!prevFiltersPassed) return false
         if (SPECIAL_FILTERS.includes(f.title)) return this.passSpecialFilter(patient, f)

         const multipleValues = filters.reduce((values, filter) => (
           filter.key === f.key ? [...values, filter.value] : values
         ), [])

         if (f.date) {
           return multipleValues.reduce((anyValuePassed, value) => (
             anyValuePassed || (patient[f.key] && new Date(patient[f.key]) < value)
           ), false)
         } else {
           return multipleValues.includes(patient[f.key])
         }
      }, true)
    })


    const patientsFiltered = patients
      .sort(sortPatients)
      .filter(searchPatients)
      .filter(filterPatients)

    const patientsRows = patientsFiltered.map(patient => {
      const pn = patient.personnummer
      //let fullpn = pn.substring(0, 2) !== '19' ? `19${pn}` : pn
      let fullpn = pn
      let personnummer = `${fullpn.substring(0, fullpn.length - 4)}••••`

      const extraCells = extraColumns.map(c => {
        let val = ''
        if (DATE_FIELDS.includes(c.key) && patient[c.key]) {
          let formattedDate = format(new Date(patient[c.key]), "dd/MM/yyyy")
          val = formattedDate === "01/01/2120" ? "Inte aktuellt" : formattedDate
        } else if (BOOLEAN_FIELDS.includes(c.key)) {
          val = patient[c.key] === true ? 'Ja' : patient[c.key] === false ? 'Nej' : ''
        } else {
          val = [null, undefined].includes(patient[c.key]) ? patient[c.key] : patient[c.key].toString()
        }
        return <td key={`${patient.id}-${c.key}`}>{val}</td>
      })

      const intervention = patient.intervention ?
        ['forstudiex', 'forstudiey', 'forstudiez'].includes(patient.intervention) ?
          patient.intervention.charAt(0).toUpperCase() +
          patient.intervention.charAt(patient.intervention.length - 1).toUpperCase() :
          patient.intervention.charAt(0).toUpperCase() : ''

      const kon = patient.personnummer && patient.personnummer.slice(-4) !== '0000' ?
          parseInt(patient.personnummer.charAt(patient.personnummer.length - 2)) % 2 === 0 ? 'K' : 'M'
      : undefined

      return (
        <tr key={patient.id} onClick={() => this.onRowClicked(patient.id)}>
          <td>{patient.fornamn} {patient.efternamn}</td>
          <td>{personnummer}</td>
          <td>{kon}</td>
          <td>{patient.login}</td>
          <td>{intervention}</td>
          {extraCells}
        </tr>
      )
    })

    const extraHeadCells = extraColumns.map(c =>
      <TableHeadCell
        key={c.key}
        custom
        title={c.title}
        checked={sort === c.key}
        order={order}
        onClick={() => this.sortBy(c.key)}
        deleteColumn={() => this.deleteColumn(c.key)}
      />
    )

    const patientsExport = patients
      .sort(sortPatients)
      .filter(filterPatients)
      .map(patient =>

      DATE_FIELDS.reduce((pat, key) => {
        if (pat[key]) {
          const formattedDate = format(new Date(pat[key]), "dd/MM/yyyy")
          return {...pat, [key]: formattedDate === "01/01/2120" ? "Inte aktuellt" : formattedDate }
        } else {
          return pat
        }
      }, patient)

    )

    return (
      <div>
        <h1>Patienter . 
          <SearchField
            type="text"
            onChange={this.onSearchChange}
            placeholder="🔍 Sök..."
            value={this.state.search}
            onKeyPress={(e) => this.onSearchEnter(e, patientsRows)}
          />
        </h1>
        <Registrera>
          <Link to="/fidura-admin/nypatient">
            <button className="button small">&#128398; Registrera ny patient</button>
          </Link>
        </Registrera>

        <FiltersContainer>
          <Filters
            onUpdate={this.onFilterUpdate}
            addColumn={this.addColumn}
            filters={filters}
          />
        </FiltersContainer>

        <Columns
          columns={extraColumns}
          addColumn={this.addColumn}
          resetColumns={() => this.setState({extraColumns: []})}
        />

        <hr />

        <TableContainer>
          { patientsRows.length > 0 &&
          <Table>
            <thead>
              <tr>
                <TableHeadCell
                  title="Namn"
                  checked={sort === 'fornamn'}
                  order={order}
                  onClick={() => this.sortBy('fornamn')}
                />
                <TableHeadCell
                  title="Personnummer"
                  checked={sort === 'personnummer'}
                  order={order}
                  onClick={() => this.sortBy('personnummer')}
                />
                <TableHeadCell
                  title="Kön"
                  checked={sort === 'kon'}
                  order={order}
                  onClick={() => this.sortBy('kon')}
                />
                <TableHeadCell
                  title="Kodnummer"
                  checked={sort === 'login'}
                  order={order}
                  onClick={() => this.sortBy('login')}
                />
                <TableHeadCell
                  title="Grupp"
                  checked={sort === 'intervention'}
                  order={order}
                  onClick={() => this.sortBy('intervention')}
                />
                { extraHeadCells }
              </tr>
            </thead>
            <tbody>
            {patientsRows}
            </tbody>
          </Table>
          }

          <PatientCounter>
            {patientsRows.length ? 
              `${patientsRows.length} patient${patientsRows.length > 1 ? 'er': ''}`:
              `Finns ingen patient som matchar dessa kriterier`
            }
          </PatientCounter>

          <PatientExport>
            <ExcelFile filename={`fidura-patienter`} element={<button disabled={patients.length === 0}>Export patients</button>}>
              <ExcelSheet data={patientsExport} name="Filtered columns">
                <ExcelColumn label="Kodnummer" value="login" />
                <ExcelColumn label="Förnamn" value="fornamn" />
                <ExcelColumn label="Efternamn" value="efternamn" />
                <ExcelColumn label="Personnummer" value="personnummer" />
                <ExcelColumn label="Intervention" value="intervention" />
                { extraColumns.map(column => <ExcelColumn label={column.title} key={column.key} value={column.key} /> )}
              </ExcelSheet>
              <ExcelSheet data={patientsExport} name="Patient Info">
                <ExcelColumn label="Kodnummer" value="login" />
                <ExcelColumn label="Förnamn" value="fornamn" />
                { AllColumns.patientInfo.map(column => <ExcelColumn label={column.title} key={column.key} value={column.key} /> )}
              </ExcelSheet>
              <ExcelSheet data={patientsExport} name="Inklusion">
                <ExcelColumn label="Kodnummer" value="login" />
                { AllColumns.inklusion.map(column => <ExcelColumn label={column.title} key={column.key} value={column.key} /> )}
              </ExcelSheet>
              <ExcelSheet data={patientsExport} name="Strålbehandling">
                <ExcelColumn label="Kodnummer" value="login" />
                { AllColumns.stralbehandling.map(column => <ExcelColumn label={column.title} key={column.key} value={column.key} /> )}
              </ExcelSheet>
              <ExcelSheet data={patientsExport} name="Blodprover">
                <ExcelColumn label="Kodnummer" value="login" />
                { AllColumns.blodprover.map(column => <ExcelColumn label={column.title} key={column.key} value={column.key} /> )}
              </ExcelSheet>
              <ExcelSheet data={patientsExport} name="Faecesprover">
                <ExcelColumn label="Kodnummer" value="login" />
                { AllColumns.faecesprover.map(column => <ExcelColumn label={column.title} key={column.key} value={column.key} /> )}
              </ExcelSheet>
              <ExcelSheet data={patientsExport} name="Enkäter">
                <ExcelColumn label="Kodnummer" value="login" />
                { AllColumns.enkater.map(column => <ExcelColumn label={column.title} key={column.key} value={column.key} /> )}
              </ExcelSheet>
              <ExcelSheet data={patientsExport} name="Uppföljningar">
                <ExcelColumn label="Kodnummer" value="login" />
                { AllColumns.uppfoljningar.map(column => <ExcelColumn label={column.title} key={column.key} value={column.key} /> )}
              </ExcelSheet>
              <ExcelSheet data={patientsExport} name="Mobilapp">
                <ExcelColumn label="Kodnummer" value="login" />
                { AllColumns.mobilapp.map(column => <ExcelColumn label={column.title} key={column.key} value={column.key} /> )}
              </ExcelSheet>
              <ExcelSheet data={patientsExport} name="Kapslar">
                <ExcelColumn label="Kodnummer" value="login" />
                { AllColumns.kapslar.map(column => <ExcelColumn label={column.title} key={column.key} value={column.key} /> )}
              </ExcelSheet>
            </ExcelFile>
          </PatientExport>
        </TableContainer>
        <Clear />
        <hr />

      </div>
    )
  }
}

export default PatientTable;
