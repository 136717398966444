import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
display: block;
clear: both;
background: #fff;
padding: 1.5em;
`

const Label = styled.label`
min-width: 6em;
display: inline-block;
`

class SelectInput extends React.Component {
  onChange = (e) => {
    this.props.onChange(e.target.value)
  }


  render() {
    return (
      <Container>
        <Label>{this.props.label}</Label>
        <select value={this.props.value} onChange={this.onChange}>
          {this.props.children}
        </select>
      </Container>
    )
  }
}

export default SelectInput;
