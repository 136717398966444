import React from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import ConnectedDatePicker from './ConnectedDatePicker';
import ConnectedCheckbox from './ConnectedCheckbox';
import ConnectedSelect from './ConnectedSelect';
import ConnectedTextInput from './ConnectedTextInput';
import SaveButton from './SaveButton';
import NavBar from './NavBar';

const Container = styled.div`
display: block;
`

class Stralbehandling extends React.Component {
  state = {
  }

  render() {
    const { patient, updatePatient } = this.props

    return (
      <Container>
        <h1><Link to="/fidura-admin/">Patient . </Link>
          <Link to={`/fidura-admin/patient/${patient.id}`}>
            {patient.fornamn} {patient.efternamn}&nbsp;
            <span className="kodnummer">({patient.login} - {patient.kort_kodnummer})</span> .
        </Link></h1>

        <NavBar />

        <ConnectedDatePicker
          id={patient.id}
          name="nybesok"
          label="Nybesök"
          date={patient.nybesok}
          onSave={updatePatient}
        />

        <hr />

        <ConnectedDatePicker
          id={patient.id}
          name="forberedande_besok"
          label="Förberedande besök"
          date={patient.forberedande_besok}
          onSave={updatePatient}
          timeSelect
        />

        <ConnectedDatePicker
          id={patient.id}
          name="stralstart"
          label="Strålstart"
          date={patient.stralstart}
          onSave={updatePatient}
          OBS="Om du ändrar det här datumet måste du manuellt ändra <b>Faecesprov 2 planerat</b> (2 dagar innan Strålstart)"
        />

        <hr />

        <ConnectedDatePicker
          id={patient.id}
          name="planerat_stralslut"
          label="Planerat strålslut"
          date={patient.planerat_stralslut}
          onSave={updatePatient}
          OBS="Om du ändrar det här datumet måste du manuellt ändra <b>Faecesprov 3 planerat</b> (3 dagar innan Planerat strålslut), <b>Faecesprov 4 planerat</b> (4 veckor efter Planerat strålslut) och <b>Enkät 2 planerat</b> (3 veckor efter Planerat strålslut)"
        />

        <ConnectedDatePicker
          id={patient.id}
          name="stralslut"
          label="Definitivt strålslut"
          date={patient.stralslut}
          onSave={updatePatient}
        />

        <ConnectedSelect
          boolean
          id={patient.id}
          name="behandling_fore_enkat3"
          label="Genomgått ny behandling för cancer före enkät 3"
          value={patient.behandling_fore_enkat3}
          onSave={updatePatient}
          options={[
            { id: 'ja', name: 'Ja'},
            { id: 'nej', name: 'Nej'},
          ]}
        />

        <ConnectedCheckbox
          hidden={!patient.behandling_fore_enkat3}
          id={patient.id}
          name="behandling_fore_enkat3_cellgifter"
          label="Cellgifter"
          checked={patient.behandling_fore_enkat3_cellgifter}
          onSave={updatePatient}
        />

        <ConnectedCheckbox
          hidden={!patient.behandling_fore_enkat3}
          id={patient.id}
          name="behandling_fore_enkat3_annat"
          label="Annat"
          checked={patient.behandling_fore_enkat3_annat}
          onSave={updatePatient}
        />

        <ConnectedTextInput
          textarea
          hidden={!patient.behandling_fore_enkat3}
          id={patient.id}
          name="behandling_fore_enkat3_anteckning"
          label="Anteckning"
          value={patient.behandling_fore_enkat3_anteckning}
          onSave={updatePatient}
        />


        <Link to={`/fidura-admin/patient/${patient.id}`}>
          <button className="button marged">← Tillbaka</button>
        </Link>
        <SaveButton />

      </Container>
    )
  }
}

export default Stralbehandling;
