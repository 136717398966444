import React from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import ConnectedDatePicker from './ConnectedDatePicker';
import ConnectedTextInput from './ConnectedTextInput';
import SaveButton from './SaveButton';
import NavBar from './NavBar';
import { format } from 'date-fns';

const Container = styled.div`
display: block;
`

const Break = styled.div`
clear: both
`

class Kapslar extends React.Component {
  state = {
  }

  render() {
    const { patient, updatePatient } = this.props

    const startKapslarInteAktuellt = patient.start_kapslar_datum &&
      format(new Date(patient.start_kapslar_datum), "dd/MM/yyyy") === "01/01/2120"

    const avslutKapslarInteAktuellt = patient.avslut_kapslar_datum &&
      format(new Date(patient.avslut_kapslar_datum), "dd/MM/yyyy") === "01/01/2120"

    const rapporteratKapslarInteAktuellt = patient.rapporterat_aterstaende_kapslar &&
      format(new Date(patient.rapporterat_aterstaende_kapslar), "dd/MM/yyyy") === "01/01/2120"

    return (
      <Container>
        <h1><Link to="/fidura-admin/">Patient . </Link>
          <Link to={`/fidura-admin/patient/${patient.id}`}>
            {patient.fornamn} {patient.efternamn}&nbsp;
            <span className="kodnummer">({patient.login} - {patient.kort_kodnummer})</span> .
        </Link></h1>

        <NavBar />

        <ConnectedTextInput
          inline
          id={patient.id}
          pattern="^[0-9]([.,][0-9])?$"
          name="antal_pasar1"
          label="Patient mottagit antal förpackningar (st)"
          value={patient.antal_pasar1}
          onSave={updatePatient}
        />

        <ConnectedDatePicker
          inline
          id={patient.id}
          name="mottagit_forpackningar1"
          label=""
          date={patient.mottagit_forpackningar1}
          onSave={updatePatient}
        />

        <Break />

        { patient.mottagit_forpackningar1 &&
          patient.antal_pasar1 &&
        <ConnectedTextInput
          inline
          id={patient.id}
          pattern="^[0-9]([.,][0-9])?$"
          name="antal_pasar2"
          label="Patient mottagit antal förpackningar (st)"
          value={patient.antal_pasar2}
          onSave={updatePatient}
        />
        }

        { patient.mottagit_forpackningar1 &&
          patient.antal_pasar1 &&
        <ConnectedDatePicker
          inline
          id={patient.id}
          name="mottagit_forpackningar2"
          label=""
          date={patient.mottagit_forpackningar2}
          onSave={updatePatient}
        />
        }

        <Break />

        { patient.mottagit_forpackningar2 &&
          patient.antal_pasar2 &&
        <ConnectedTextInput
          inline
          id={patient.id}
          pattern="^[0-9]([.,][0-9])?$"
          name="antal_pasar3"
          label="Patient mottagit antal förpackningar (st)"
          value={patient.antal_pasar3}
          onSave={updatePatient}
        />
        }

        { patient.mottagit_forpackningar2 &&
          patient.antal_pasar2 &&
        <ConnectedDatePicker
          inline
          id={patient.id}
          name="mottagit_forpackningar3"
          label=""
          date={patient.mottagit_forpackningar3}
          onSave={updatePatient}
        />
        }

        <Break />

        { patient.mottagit_forpackningar3 &&
          patient.antal_pasar3 &&
        <ConnectedTextInput
          inline
          id={patient.id}
          pattern="^[0-9]([.,][0-9])?$"
          name="antal_pasar4"
          label="Patient mottagit antal förpackningar (st)"
          value={patient.antal_pasar4}
          onSave={updatePatient}
        />
        }

        { patient.mottagit_forpackningar3 &&
          patient.antal_pasar3 &&
        <ConnectedDatePicker
          inline
          id={patient.id}
          name="mottagit_forpackningar4"
          label=""
          date={patient.mottagit_forpackningar4}
          onSave={updatePatient}
        />
        }

        <Break />

        { patient.mottagit_forpackningar4 &&
          patient.antal_pasar4 &&
        <ConnectedTextInput
          inline
          id={patient.id}
          pattern="^[0-9]([.,][0-9])?$"
          name="antal_pasar5"
          label="Patient mottagit antal förpackningar (st)"
          value={patient.antal_pasar5}
          onSave={updatePatient}
        />
        }

        { patient.mottagit_forpackningar4 &&
          patient.antal_pasar4 &&
        <ConnectedDatePicker
          inline
          id={patient.id}
          name="mottagit_forpackningar5"
          label=""
          date={patient.mottagit_forpackningar5}
          onSave={updatePatient}
        />
        }

        <Break />

        <ConnectedTextInput
          inline
          id={patient.id}
          disabled={true}
          name="totalt_forpackningar"
          label="Totalt förpackningar (st)"
          value={patient.totalt_forpackningar}
          onSave={updatePatient}
        />

        <hr />

        <ConnectedTextInput
          inline
          id={patient.id}
          name="start_kapslar"
          label="Start intag kapslar"
          value={patient.start_kapslar}
          onSave={updatePatient}
          disabled={startKapslarInteAktuellt}
        />

        <ConnectedDatePicker
          inline
          id={patient.id}
          name="start_kapslar_datum"
          label=""
          date={patient.start_kapslar_datum}
          onSave={updatePatient}
          kanInteAktuellt
        />

        <Break />

        <ConnectedTextInput
          inline
          id={patient.id}
          name="avslut_kapslar"
          label="Avslut intag kapslar"
          value={patient.avslut_kapslar}
          onSave={updatePatient}
          disabled={avslutKapslarInteAktuellt}
        />

        <ConnectedDatePicker
          inline
          id={patient.id}
          name="avslut_kapslar_datum"
          label=""
          date={patient.avslut_kapslar_datum}
          onSave={updatePatient}
          kanInteAktuellt
        />

        <hr />

        <ConnectedTextInput
          inline
          id={patient.id}
          pattern="^[0-9]+([.,][0-9]+)?$"
          name="antal_pasar_rapporterat"
          label="Rapporterat återstående kapslar (antal förpackningar)"
          value={patient.antal_pasar_rapporterat}
          onSave={updatePatient}
          disabled={rapporteratKapslarInteAktuellt}
        />

        <ConnectedDatePicker
          inline
          id={patient.id}
          name="rapporterat_aterstaende_kapslar"
          label=""
          date={patient.rapporterat_aterstaende_kapslar}
          onSave={updatePatient}
          kanInteAktuellt
        />

        <div>

          <Link to={`/fidura-admin/patient/${patient.id}`}>
            <button className="button marged">← Tillbaka</button>
          </Link>
          <SaveButton />
        </div>

      </Container>
    )
  }
}

export default Kapslar;
