import React from 'react';
import styled from 'styled-components';
import DatePicker, { registerLocale } from "react-datepicker";
import { format } from 'date-fns';
import sv from 'date-fns/locale/sv';
registerLocale('sv', sv);

const Container = styled.div`
padding: 1rem 0.2rem;
`

const FilterBox = styled.div`
display: inline-block;
position: relative;
background: #eee;
border-radius: 0.5em;
border: 1px solid #aaa;
padding: 0.5em 2em 0.5em 1em;
margin-bottom: 1em;
margin: 0 0.5em 1em;
`

const LaggTillButton = styled.button`
display: inline-block;
position: relative;
background: transparent;
border-radius: 0.5em;
border: none;
padding: 0.5em 0.5em;
margin: 0 0.2em 1em;
font-weight: bold;
font-size: inherit;
cursor: pointer;

&:hover {
  color: blue;
}
`

const ClearButton = styled.button`
display: inline-block;
position: relative;
background: transparent;
border-radius: 0.5em;
border: none;
padding: 0.5em 1em;
margin: 0 0.2em 1em;
font-weight: bold;
font-size: inherit;
cursor: pointer;

&:hover {
  color: red;
}
`

const RemoveButton = styled.span`
display: inline-block;
position: absolute;
vertical-align: middle;
font-weight: bold;
cursor: pointer;
top: 0.25em;
right: 0.7em;
font-size: 1.2em;
`

const ClosePopUpButton = styled.span`
position: absolute;
top: 0.8rem;
right: 1.8rem;
font-size: 2em;
display: inline-block;
vertical-align: top;
font-weight: bold;
cursor: pointer;
`

const PopUpContainer = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.7);
z-index: 100;
`
const PopUp = styled.div`
position: relative;
width: 80%;
height: 90%;
background-color: white;
border-radius: 1rem;
border: 1px solid #aaa;
margin: 1rem auto;
padding: 0.5rem 2rem;
overflow-y: hidden;
`

const LeftCol = styled.div`
display: inline-block;
width: 25%;
min-width: 100px;
height: 100%;
margin: 0;
border-right: 1px solid #aaa;
`

const RightCol = styled.div`
overflow: auto;
display: inline-block;
vertical-align: top;
width: 70%;
height: 90%;
margin: 0;
text-align: left;
`

const Tab = styled.div`
text-align: right;
font-weight: 300;
font-size: 1.5rem;
padding: 1rem 1rem 0 0;
cursor: pointer;

& span {
  visibility: hidden;
}

&:hover {
 color: blue;
}

&.active {
  font-weight: bold;
}

&:hover span,
&.active span {
  visibility: visible;
}
`

class Filters extends React.Component {
  state = {
    popUp: false,
    tab: 'Inklusion'
  }

  addFilter = (filter, callback) => {
    const filters = this.props.filters
    if (!filters.find(f => f.title === filter.title)) {
      this.props.onUpdate([...filters, filter], () => {
        if (filter.column) {
          this.props.addColumn(filter.column, callback)
        } else {
          callback()
        }
      })
    }
  }

  removeFilter = (filter, callback) => {
    this.props.onUpdate(this.props.filters.filter(f => f.title !== filter.title), callback)
  }

  formatDate = (date) =>
    format(date, "dd/MM/yyyy")

  escPress = (event) =>
    event.keyCode === 27 && this.hidePopUp()

  showPopUp = () => {
    this.setState({
      popUp: true
    }, () =>
      document.addEventListener("keydown", this.escPress, false)
    )
  }

  hidePopUp = () => {
    this.setState({
      popUp: false
    }, () =>
      document.removeEventListener("keydown", this.escPress, false)
    )
  }

  render() {
    const { popUp, tab } = this.state
    const { filters } = this.props

    const filterBoxes = filters.map(filter =>
      <FilterBox key={filter.title}>
          {filter.date && filter.title.endsWith('innan') ?
            `${filter.title} ${this.formatDate(filter.value)}`:
            filter.title
          }

        <RemoveButton
          onClick={() => this.removeFilter(filter)}>
          &times;
        </RemoveButton>
      </FilterBox>
    )

    const Tabs = [
      'Inklusion',
      'Strålbehandling',
      'Blodprover',
      'Faecesprover',
      'Enkäter',
      'Uppföljningar',
    ].map(t =>
      <Tab
        key={t}
        className={tab === t ? 'active': ''}
        onClick={() => this.setState({tab: t})}
      >{t} <span>→</span></Tab>
    )

    const generateFilterButtons = (availableFilters) => {

      return availableFilters.map(f => {
        let disabled = filters.find(filter => filter.title === f.title)
        let chooseDate = f.date && f.value === 'innan'

        let FilterButton = ({ value, onClick }) => (
          Array.isArray(f.value) ?
            <select
              disabled={disabled}
              className="button"
              value="default"
              onChange={(e) => {
                let val = e.target.value
                if (e.target.value === 'true') val = true
                else if (e.target.value === 'false') val = false
                let title = f.value.find(v => v[0] === val)[1]

                this.addFilter({...f,
                  value: val,
                  title: `${f.title} "${title}"`},
                () => this.hidePopUp())
              }}
            >
            <option value='default' disabled>+ {f.title}</option>
            {f.value.map((v, k) => <option
              key={v[1]}
              value={v[0]}
              disabled={filters.find(filter => filter.title === `${f.title} "${v[0]}"`)}
              >{v[1].toString()}</option>)}
            </select> :
            <button
              disabled={disabled}
              className="button"
              onClick={onClick}
            >{disabled ? '': '+ '}{f.title}</button>
        )

        return chooseDate ?
          <div key={f.title}>
            <div className="filter">
              <DatePicker
                disabled={disabled}
                dateFormat="dd/MM/yyyy"
                locale="sv"
                onChange={(date) =>
                    this.addFilter({...f, value: date},
                      () => this.hidePopUp())
                }
                title={'Välj datum'}
                todayButton={"Idag"}
                customInput={<FilterButton />}
              />
            </div>
            { f.break && <br /> }
          </div> :
          <div key={f.title}>
            <div className="filter">
              <FilterButton onClick={() =>
                this.addFilter(f, () => this.hidePopUp())
              } />
            </div>
            { f.break && <br /> }
          </div>
      })
    }

    return (
      <Container>
        { filters.length > 0 &&
          <h3 style={{display: 'inline-block'}}>Filter : &nbsp; </h3>
        }
        {filterBoxes}

        <LaggTillButton
          onClick={() => this.showPopUp()}
        >+ Lägg till nytt filter</LaggTillButton>

        { filters.length > 0 &&
          <ClearButton
            onClick={() => this.props.onUpdate([])}
          >&times; Rensa filter</ClearButton>
        }


        { popUp &&
          <PopUpContainer>
            <PopUp>
              <h3>&nbsp;</h3>
              <LeftCol>
                {Tabs}
              </LeftCol>
              <RightCol>
                {{
                  'Inklusion': generateFilterButtons([
                    { title: 'Har ett kort kodnummer',
                      key: 'kort_kodnummer',
                      value: null
                    },
                    { title: 'Nya patienter',
                      key: 'nya_patienter',
                      value: null
                    },
                    { title: 'Inkluderade patienter',
                      key: 'inkuderade_patienter',
                      value: null,
                      column: {
                        key: 'samtycke_lamnat',
                        title:'Samtycke lämnat datum'
                      }
                    },
                    { title: 'Exkluderade patienter',
                      key: 'excluderad',
                      value: new Date(),
                      date: true,
                      column: {
                        key: 'excluderad',
                        title:'Exkluderad datum'
                      }
                    },
                    { title: 'Exkluderad orsak',
                      key: 'excluderad_reason',
                      value: [
                        ['stomi', 'Stomi som hindrar deltagande'],
                        ['palliativ_behandling', 'Palliativ behandling'],
                        ['ingen_stralbehandling', 'Ingen/uppskjuten strålbehandling'],
                        ['svar_svenska', 'Svårigheter svenska'],
                        ['annan_orsak', 'Annan orsak'],
                        ['ingen_kontakt', 'Ingen kontakt'],
                      ],
                    },
                    { title: 'Patienter som avbrutit studien',
                      key: 'avbryter_studien',
                      value: new Date(),
                      date: true,
                      column: {
                        key: 'avbryter_studien',
                        title:'Avbryter studien datum'
                      }
                    },
                    { title: 'Patienten avbryter studien orsak',
                      key: 'avbryter_studien_reason',
                      value: [
                        ['andra_kosten', 'Vill ej ändra kosten'],
                        ['kapslar', 'Svårigheter med kapslar'],
                        ['orkar_inte', 'Orkar inte delta'],
                        ['sociala_omstandigheter', 'Sociala omständigheter'],
                        ['sjukhusinlaggning', 'Sjukhusinläggning'],
                        ['inte_intresserad', 'Inte intresserad'],
                        ['ingen_kontakt', 'Ingen kontakt'],
                        ['annan_orsak', 'Annan orsak'],
                        ['orsak_ej_angiven', 'Orsak ej angiven'],
                      ],
                    },
                    { title: 'Kvinnor',
                      key: 'ar_en_kvinna',
                      value: null
                    },
                    { title: 'Män',
                      key: 'ar_en_man',
                      value: null
                    },
                    { title: 'Brev 1 skickat/lämnat innan',
                      key: 'brev1_skickat',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'brev1_skickat',
                        title:'Brev 1 skickat/lämnat'
                      }
                    },
                    { title: 'Telefonsamtal A svar',
                      key: 'telefonsamtal_a_tackat',
                      value: [
                        ['ja', 'Ja'],
                        ['nej', 'Nej'],
                        ['vetEj', 'Vet ej']
                      ],
                      column: {
                        key: 'telefonsamtal_a',
                        title:'Telefonsamtal A'
                      }
                    },
                    { title: 'Telefonsamtal A - Nej - orsak',
                      key: 'telefonsamtal_a_reason',
                      value: [
                        ['andra_kosten', 'Vill ej ändra kosten'],
                        ['kapslar', 'Svårigheter med kapslar'],
                        ['orkar_inte', 'Orkar inte delta'],
                        ['sociala_omstandigheter', 'Sociala omständigheter'],
                        ['sjukhusinlaggning', 'Sjukhusinläggning'],
                        ['inte_intresserad', 'Inte intresserad'],
                        ['ingen_kontakt', 'Ingen kontakt'],
                        ['annan_orsak', 'Annan orsak'],
                        ['orsak_ej_angiven', 'Orsak ej angiven'],
                      ],
                    },
                    { title: 'Telefonsamtal A innan',
                      key: 'telefonsamtal_a',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'telefonsamtal_a',
                        title:'Telefonsamtal A'
                      }
                    },
                    { title: 'Telefonsamtal B svar',
                      key: 'telefonsamtal_b_tackat',
                      value: [
                        ['ja', 'Ja'],
                        ['nej', 'Nej'],
                      ],
                      column: {
                        key: 'telefonsamtal_b',
                        title:'Telefonsamtal B'
                      }
                    },
                    { title: 'Telefonsamtal B - Nej - orsak',
                      key: 'telefonsamtal_b_reason',
                      value: [
                        ['andra_kosten', 'Vill ej ändra kosten'],
                        ['kapslar', 'Svårigheter med kapslar'],
                        ['orkar_inte', 'Orkar inte delta'],
                        ['sociala_omstandigheter', 'Sociala omständigheter'],
                        ['sjukhusinlaggning', 'Sjukhusinläggning'],
                        ['ingen_kontakt', 'Ingen kontakt'],
                        ['annan_orsak', 'Annan orsak'],
                        ['orsak_ej_angiven', 'Orsak ej angiven'],
                      ],
                    },
                    { title: 'Telefonsamtal B innan',
                      key: 'telefonsamtal_b',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'telefonsamtal_b',
                        title:'Telefonsamtal B'
                      }
                    },
                    { title: 'Inklusionsmöte innan',
                      key: 'inklusionsmote',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'inklusionsmote',
                        title:'Inklusionsmöte'
                      }
                    },
                    { title: 'Skriftligt samtycke innan',
                      key: 'samtycke_lamnat',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'samtycke_lamnat',
                        title:'Skriftligt samtycke datum'
                      }
                    },
                    { title: 'Skriftligt samtycke format',
                      key: 'samtycke_format',
                      value: [
                        ['webb', 'Webb'],
                        ['papper', 'Papper']
                      ],
                    },
                    { title: 'Inskriven i Melior',
                      key: 'melior',
                      value: [
                        [true, 'Ja'],
                        [false, 'Nej']
                      ]
                    },
                    { title: 'Intervention',
                      key: 'intervention',
                      value: [
                        ['x', 'X'],
                        ['y', 'Y'],
                        ['z', 'Z'],
                        ['forstudie', 'Förstudie'],
                        ['forstudiex', 'Förstudie X'],
                        ['forstudiey', 'Förstudie Y'],
                        ['forstudiez', 'Förstudie Z']
                      ],
                    },
                    { title: 'Enkät format',
                      key: 'enkat',
                      value: [
                        ['webb', 'Webb'],
                        ['papper', 'Papper']
                      ],
                    },
                    { title: 'App format',
                      key: 'app',
                      value: [
                        ['webb', 'Webb'],
                        ['papper', 'Papper']
                      ],
                    },
                    { title: 'Faeceskit 1-4 + remiss skickat innan',
                      key: 'faeceskit1_4_skickat',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'faeceskit1_4_skickat',
                        title: 'Faeceskit 1-4 + remiss skickat datum'
                      }
                    },
                    { title: 'Faeceskit 5 + remiss skickat innan',
                      key: 'faeceskit5_skickat',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'faeceskit5_skickat',
                        title: 'Faeceskit 5 + remiss skickat datum'
                      }
                    },
                    { title: 'Skriftligt samtycke',
                      key: 'samtycke',
                      value: [
                        [true, 'Ja'],
                        [false, 'Nej']
                      ],
                      column: {
                        key: 'samtycke_lamnat',
                        title:'Samtycke lämnat datum'
                      }
                    }
                  ]),

                  'Strålbehandling': generateFilterButtons([
                    { title: 'Pågående strålbehandling',
                      key: 'pagaende_stralbehandling',
                      value: null,
                      column: {
                        key: 'planerat_stralslut',
                        title:'Planerat strålslut'
                      }
                    },
                    { title: 'Nybesök innan',
                      key: 'nybesok',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'nybesok',
                        title:'Nybesök datum'
                      }
                    },
                    { title: 'Förberedande besök innan',
                      key: 'forberedande_besok',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'forberedande_besok',
                        title: 'Förberedande besök datum'
                      }
                    },
                    { title: 'Strålstart innan',
                      key: 'stralstart',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'stralstart',
                        title: 'Strålstart datum'
                      }
                    },
                    { title: 'Planerat strålslut innan',
                      key: 'planerat_stralslut',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'planerat_stralslut',
                        title: 'Planerat strålslut datum'
                      }
                    },
                    { title: 'Definitivt strålslut innan',
                      key: 'planerat_stralslut',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'stralslut',
                        title: 'Definitivt strålslut datum'
                      }
                    },
                    { title: 'Genomgått ny behandling för cancer före enkät 3',
                      key: 'behandling_fore_enkat3',
                      value: [
                        [true, 'Ja'],
                        [false, 'Nej']
                      ],
                      column: {
                        key: 'behandling_fore_enkat3',
                        title: 'Genomgått ny behandling för cancer före enkät 3'
                      }
                    },
                    { title: 'Cellgifter behandling före enkät 3',
                      key: 'behandling_fore_enkat3_cellgifter',
                      value: [
                        [true, 'Ja'],
                        [false, 'Nej']
                      ],
                      column: {
                        key: 'behandling_fore_enkat3_cellgifter',
                        title: 'Cellgifter behandling före enkät 3'
                      }
                    },
                    { title: 'Annan behandling före enkät 3',
                      key: 'behandling_fore_enkat3_annat',
                      value: [
                        [true, 'Ja'],
                        [false, 'Nej']
                      ],
                      column: {
                        key: 'behandling_fore_enkat3_annat',
                        title: 'Annan behandling före enkät 3'
                      }
                    },
                  ]),

                  'Blodprover': generateFilterButtons([
                    { title: 'Blodprov 1 (baseline) planerat innan',
                      key: 'blodprov1_planerat',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'blodprov1_planerat',
                        title:'Blodprov 1 (baseline) planerat datum'
                      }
                    },
                    { title: 'Blodprov 1 (baseline) utfört innan',
                      key: 'blodprov1_utfort',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'blodprov1_utfort',
                        title:'Blodprov 1 (baseline) utfört datum'
                      }
                    },
                    { title: 'Blodprov 2 (strål) planerat innan',
                      key: 'blodprov2_planerat',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'blodprov2_planerat',
                        title:'Blodprov 2 (strål) planerat datum'
                      }
                    },
                    { title: 'Blodprov 2 (strål) utfört innan',
                      key: 'blodprov2_utfort',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'blodprov2_utfort',
                        title:'Blodprov 2 (strål) utfört datum'
                      }
                    },
                    { title: 'Blodprov 3 (uppföljning kort) planerat innan',
                      key: 'blodprov3_planerat',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'blodprov3_planerat',
                        title:'Blodprov 3 (uppföljning kort) planerat datum'
                      }
                    },
                    { title: 'Blodprov 3 (uppföljning kort) utfört innan',
                      key: 'blodprov3_utfort',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'blodprov3_utfort',
                        title:'Blodprov 3 (uppföljning kort) utfört datum'
                      }
                    },
                    { title: 'Blodprov 4 (uppföljning lång) planerat innan',
                      key: 'blodprov4_planerat',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'blodprov4_planerat',
                        title:'Blodprov 4 (uppföljning lång) planerat datum'
                      }
                    },
                    { title: 'Blodprov 4 (uppföljning lång) utfört innan',
                      key: 'blodprov4_utfort',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'blodprov4_utfort',
                        title:'Blodprov 4 (uppföljning lång) utfört datum'
                      }
                    },
                  ]),

                  'Faecesprover': generateFilterButtons([
                    { title: 'Faecesprov 1 (baseline) planerat innan',
                      key: 'faecesprov1_planerat',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'faecesprov1_planerat',
                        title:'Faecesprov 1 (baseline) planerat datum'
                      }
                    },
                    { title: 'Faecesprov 1 (baseline) utfört innan',
                      key: 'faecesprov1_utfort',
                      value: 'innan',
                      date: true,
                      time: true,
                      column: {
                        key: 'faecesprov1_utfort',
                        title:'Faecesprov 1 (baseline) utfört datum'
                      }
                    },
                    { title: 'Faecesprov 2 (strålstart) planerat innan',
                      key: 'faecesprov2_planerat',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'faecesprov2_planerat',
                        title:'Faecesprov 2 (strålstart) planerat datum'
                      }
                    },
                    { title: 'Faecesprov 2 (strålstart) utfört innan',
                      key: 'faecesprov2_utfort',
                      value: 'innan',
                      date: true,
                      time: true,
                      column: {
                        key: 'faecesprov2_utfort',
                        title:'Faecesprov 2 (strålstart) utfört datum'
                      }
                    },
                    { title: 'Faecesprov 3 (strålslut) planerat innan',
                      key: 'faecesprov3_planerat',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'faecesprov3_planerat',
                        title:'Faecesprov 3 (strålslut) planerat datum'
                      }
                    },
                    { title: 'Faecesprov 3 (strålslut) utfört innan',
                      key: 'faecesprov3_utfort',
                      value: 'innan',
                      date: true,
                      time: true,
                      column: {
                        key: 'faecesprov3_utfort',
                        title:'Faecesprov 3 (strålslut) utfört datum'
                      }
                    },
                    { title: 'Faecesprov 4 (uppföljning kort) planerat innan',
                      key: 'faecesprov4_planerat',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'faecesprov4_planerat',
                        title:'Faecesprov 4 (uppföljning kort) planerat datum'
                      }
                    },
                    { title: 'Faecesprov 4 (uppföljning kort) utfört innan',
                      key: 'faecesprov4_utfort',
                      value: 'innan',
                      date: true,
                      time: true,
                      column: {
                        key: 'faecesprov4_utfort',
                        title:'Faecesprov 4 (uppföljning kort) utfört datum'
                      }
                    },
                    { title: 'Faecesprov 5 (uppföljning lång) planerat innan',
                      key: 'faecesprov5_planerat',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'faecesprov5_planerat',
                        title:'Faecesprov 5 (uppföljning lång) planerat datum'
                      }
                    },
                    { title: 'Faecesprov 5 (uppföljning lång) utfört innan',
                      key: 'faecesprov5_utfort',
                      value: 'innan',
                      date: true,
                      time: true,
                      column: {
                        key: 'faecesprov5_utfort',
                        title:'Faecesprov 5 (uppföljning lång) utfört datum'
                      }
                    },
                  ]),

                  'Enkäter': generateFilterButtons([
                    { title: 'Enkät 1 ifylld innan',
                      key: 'enkat1_fyllt',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'enkat1_fyllt',
                        title: 'Enkät 1 ifylld datum'
                      }
                    },
                    { title: 'Enkät 2 ifylld innan',
                      key: 'enkat2_fyllt',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'enkat2_fyllt',
                        title: 'Enkät 2 ifylld datum'
                      }
                    },
                    { title: 'Enkät 3 ifylld innan',
                      key: 'enkat3_fyllt',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'enkat3_fyllt',
                        title: 'Enkät 3 ifylld datum'
                      }
                    },
                  ]),

                  'Uppföljningar': generateFilterButtons([
                    { title: 'Återbesök 1, Toxkontroll innan',
                      key: 'aterbesok1',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'aterbesok1',
                        title:'Återbesök 1, Toxkontroll datum'
                      }
                    },
                    { title: 'Återbesök 2 innan',
                      key: 'aterbesok2',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'aterbesok2',
                        title:'Återbesök 2 datum'
                      }
                    },
                    { title: 'Telefonuppföljning 1 innan',
                      key: 'telefonuppfoljning1',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'telefonuppfoljning1',
                        title:'Telefonuppföljning 1 datum'
                      }
                    },
                    { title: 'Telefonuppföljning 2 innan',
                      key: 'telefonuppfoljning2',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'telefonuppfoljning2',
                        title:'Telefonuppföljning 2 datum'
                      }
                    },
                    { title: 'Telefonuppföljning 3 innan',
                      key: 'telefonuppfoljning3',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'telefonuppfoljning3',
                        title:'Telefonuppföljning 3 datum'
                      }
                    },
                    { title: 'Telefonuppföljning 4 innan',
                      key: 'telefonuppfoljning4',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'telefonuppfoljning4',
                        title:'Telefonuppföljning 4 datum'
                      }
                    },
                    { title: 'Extra telefonuppföljning innan',
                      key: 'extra_telefonuppfoljning',
                      value: 'innan',
                      date: true,
                      column: {
                        key: 'extra_telefonuppfoljning',
                        title:'Extra telefonuppföljning datum'
                      }
                    },
                  ]),

                }[tab]}
              </RightCol>
              <ClosePopUpButton
                onClick={() => this.hidePopUp()}
              >&times;</ClosePopUpButton>
            </PopUp>
          </PopUpContainer>
        }
      </Container>
    )
  }
}

export default Filters;
