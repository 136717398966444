import React from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import ConnectedSelect from './ConnectedSelect';
import ConnectedDatePicker from './ConnectedDatePicker';
import ConnectedCheckbox from './ConnectedCheckbox';
import ConnectedTextInput from './ConnectedTextInput';
import SaveButton from './SaveButton';
import NavBar from './NavBar';

const Container = styled.div`
display: block;
`

const Warning = styled.div`
color: red;
margin-left: 10em;
`

class Enkater extends React.Component {
  state = {
  }

  render() {
    const { patient, updatePatient, updatePandR } = this.props

    // Page logic
    let now = new Date()
    let date = (key) => new Date(patient[key])

    const enkat1_date_reached = 
        patient.enkat1_fyllt && date('enkat1_fyllt') < now
    const enkat2_date_reached = 
        patient.enkat2_fyllt && date('enkat2_fyllt') < now
    const enkat3_date_reached = 
        patient.enkat3_fyllt && date('enkat3_fyllt') < now
    const webenkat1 = patient.enkat === 'webb' || patient.enkat1_format === 'webb'
    const webenkat2 = patient.enkat === 'webb' || patient.enkat2_format === 'webb'
    const webenkat3 = patient.enkat === 'webb' || patient.enkat3_format === 'webb'

    return (
      <Container>
        <h1><Link to="/fidura-admin/">Patient . </Link>
          <Link to={`/fidura-admin/patient/${patient.id}`}>
            {patient.fornamn} {patient.efternamn}&nbsp;
            <span className="kodnummer">({patient.login} - {patient.kort_kodnummer})</span> .
        </Link></h1>

        <NavBar />

        <ConnectedCheckbox
          id={patient.id}
          name="skicka_ej_enkater_paminelser"
          label="Skicka ej sms-påminnelse enkäter"
          checked={patient.skicka_ej_enkater_paminelser}
          onSave={updatePatient}
          long
        />

        <ConnectedDatePicker
          id={patient.id}
          name="enkat1_planerat"
          label="Enkät 1 planerat"
          date={patient.enkat1_planerat}
          onSave={updatePatient}
          note="Påminnelse 2 dagar efter detta datum om enkäten ej är ifylld (fylls i automatiskt samma dag som Inklusionsmöte). Upprepas tre gånger om webbenkät är vald"
          kanInteAktuellt
        />

        <ConnectedDatePicker
          id={patient.id}
          name="enkat1_skickad"
          label="Enkät 1 skickad"
          date={patient.enkat1_skickad}
          onSave={updatePandR}
          kanInteAktuellt
        />

        <ConnectedDatePicker
          id={patient.id}
          disabled={webenkat1}
          starred={webenkat1}
          name="enkat1_fyllt"
          label="Enkät 1 ifylld"
          date={patient.enkat1_fyllt}
          onSave={updatePandR}
          kanInteAktuellt
        />

        <ConnectedDatePicker
          hidden={!enkat1_date_reached}
          id={patient.id}
          name="enkat1_kontrollerat"
          label="Enkät 1 kontrollerat"
          date={patient.enkat1_kontrollerat}
          onSave={updatePandR}
        />

        <ConnectedSelect
          inline
          hidden={!enkat1_date_reached}
          id={patient.id}
          name="enkat1_format"
          label="Enkät 1 format"
          value={patient.enkat1_format}
          onSave={updatePatient}
          options={[
            { id: 'webb', name: 'Webb'},
            { id: 'papper', name: 'Papper'},
            { id: 'telefon', name: 'Telefon'},
            { id: 'papperKortfattadBCA', name: 'Papper Kortfattad - B, C, A'},
            { id: 'telefonKortfattadB', name: 'Telefon Kortfattad - B'},
            { id: 'telefonKortfattadBC', name: 'Telefon Kortfattad - B, C'},
            { id: 'telefonKortfattadBCA', name: 'Telefon Kortfattad - B, C, A'},
          ]}
        />

        <ConnectedTextInput
          inline
          hidden={!enkat1_date_reached}
          textarea
          id={patient.id}
          name="enkat1_anteckning"
          label="Anteckning"
          value={patient.enkat1_anteckning}
          onSave={updatePatient}
        />

        { enkat1_date_reached && <hr /> }

        <ConnectedDatePicker
          id={patient.id}
          name="enkat2_planerat"
          label="Enkät 2 planerat"
          date={patient.enkat2_planerat}
          onSave={updatePatient}
          note="Påminnelse detta datum (fylls i automatiskt en vecka innan Återbesök 1 (Toxkontroll) eller tre veckor efter Planerat strålslut). Upprepas tre gånger om webbenkät är vald"
          kanInteAktuellt
        />

        <ConnectedDatePicker
          id={patient.id}
          name="enkat2_skickad"
          label="Enkät 2 skickad"
          date={patient.enkat2_skickad}
          onSave={updatePandR}
          kanInteAktuellt
        />

        <ConnectedDatePicker
          id={patient.id}
          disabled={webenkat2}
          starred={webenkat2}
          name="enkat2_fyllt"
          label="Enkät 2 ifylld"
          date={patient.enkat2_fyllt}
          onSave={updatePandR}
          kanInteAktuellt
        />

        <ConnectedDatePicker
          hidden={!enkat2_date_reached}
          id={patient.id}
          name="enkat2_kontrollerat"
          label="Enkät 2 kontrollerat"
          date={patient.enkat2_kontrollerat}
          onSave={updatePandR}
        />

        <ConnectedSelect
          inline
          hidden={!enkat2_date_reached}
          id={patient.id}
          name="enkat2_format"
          label="Enkät 2 format"
          value={patient.enkat2_format}
          onSave={updatePatient}
          options={[
            { id: 'webb', name: 'Webb'},
            { id: 'papper', name: 'Papper'},
            { id: 'telefon', name: 'Telefon'},
            { id: 'papperKortfattadBCA', name: 'Papper Kortfattad - B, C, A'},
            { id: 'telefonKortfattadB', name: 'Telefon Kortfattad - B'},
            { id: 'telefonKortfattadBC', name: 'Telefon Kortfattad - B, C'},
            { id: 'telefonKortfattadBCA', name: 'Telefon Kortfattad - B, C, A'},
          ]}
        />

        <ConnectedTextInput
          inline
          textarea
          hidden={!enkat2_date_reached}
          id={patient.id}
          name="enkat2_anteckning"
          label="Anteckning"
          value={patient.enkat2_anteckning}
          onSave={updatePatient}
        />

        { enkat2_date_reached && <hr /> }

        <ConnectedDatePicker
          id={patient.id}
          name="enkat3_planerat"
          label="Enkät 3 planerat"
          date={patient.enkat3_planerat}
          onSave={updatePatient}
          note="Påminnelse detta datum (fylls i automatiskt en vecka innan Återbesök 2). Upprepas tre gånger om webbenkät är vald."
          kanInteAktuellt
        />

        <ConnectedDatePicker
          id={patient.id}
          name="enkat3_skickad"
          label="Enkät 3 skickad"
          date={patient.enkat3_skickad}
          onSave={updatePandR}
          kanInteAktuellt
        />

        <ConnectedDatePicker
          id={patient.id}
          disabled={webenkat3}
          starred={webenkat3}
          name="enkat3_fyllt"
          label="Enkät 3 ifylld"
          date={patient.enkat3_fyllt}
          onSave={updatePandR}
          kanInteAktuellt
        />

        <ConnectedDatePicker
          hidden={!enkat3_date_reached}
          id={patient.id}
          name="enkat3_kontrollerat"
          label="Enkät 3 kontrollerat"
          date={patient.enkat3_kontrollerat}
          onSave={updatePandR}
        />

        <ConnectedSelect
          inline
          hidden={!enkat3_date_reached}
          id={patient.id}
          name="enkat3_format"
          label="Enkät 3 format"
          value={patient.enkat3_format}
          onSave={updatePatient}
          options={[
            { id: 'webb', name: 'Webb'},
            { id: 'papper', name: 'Papper'},
            { id: 'telefon', name: 'Telefon'},
            { id: 'papperKortfattadBCA', name: 'Papper Kortfattad - B, C, A'},
            { id: 'telefonKortfattadB', name: 'Telefon Kortfattad - B'},
            { id: 'telefonKortfattadBC', name: 'Telefon Kortfattad - B, C'},
            { id: 'telefonKortfattadBCA', name: 'Telefon Kortfattad - B, C, A'},
          ]}
        />

        <ConnectedTextInput
          inline
          hidden={!enkat3_date_reached}
          textarea
          id={patient.id}
          name="enkat3_anteckning"
          label="Anteckning"
          value={patient.enkat3_anteckning}
          onSave={updatePatient}
        />

        { (webenkat1 || webenkat2 || webenkat3) &&
        <Warning>* Datum ifylles automatiskt från webb enkäten</Warning>
        }

        <Link to={`/fidura-admin/patient/${patient.id}`}>
          <button className="button marged">← Tillbaka</button>
        </Link>
        <SaveButton />

      </Container>
    )
  }
}

export default Enkater;
