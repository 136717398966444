import React from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';

const Nav = styled.ul`
list-style-type: none;
margin: 0 0 1em;
padding: 0;

& a {
  display: inline-block;
  padding: 0.5em 1em;
  margin: 0.5em 1em;
  border: 1px solid #aaa;
  color: #333;
  text-decoration: none;
  border-radius: 3px;
  font-size: 1.2em;
  background: #eee;
}

& a:hover,
& a:focus,
& a:active,
& a.active {
  background: #ccc;
}

& a.active {
  font-weight: bold
}
`

class NavBar extends React.Component {
  render() {
    const { id } = this.props
    const path = id ? `${id}/` : ''

    const Links = ['Inklusion', 'Strålbehandling', 'Prover', 'Enkäter',
      'Uppföljningar', 'Mobilapp', 'Kapslar'].map(tab => {
        let _tab = tab.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
        let classes = window.location.pathname.split("/").pop() === _tab ? 'active' : ''

        return <Link key={_tab} className={classes} to={`${path}${_tab}`}><li>{tab}</li></Link>
      })

    return <Nav> { Links } </Nav>
  }
}

export default NavBar;
