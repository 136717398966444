import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
display: block;
clear: both;
background: #fff;
padding: 1.5em;
`

const Label = styled.label`
min-width: 6em;
display: inline-block;
`

class TextInput extends React.Component {
  onChange = (e) => {
    this.props.onChange(e.target.value)
  }

  onEnter = (e) => {
    if(e.which === 13) {
      this.props.onEnter()
    }
  }


  render() {
    return (
      <Container>
        <Label>{this.props.label}</Label>
        <input
          type={this.props.password ? 'password': 'text'}
          onChange={this.onChange}
          onKeyPress={this.props.onEnter ? this.onEnter : () => {}}
          value={this.props.value}
          autoFocus={this.props.autoFocus}
        />
      </Container>
    )
  }
}

export default TextInput;
