import React from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import ConnectedTextInput from './ConnectedTextInput';
import ConnectedDatePicker from './ConnectedDatePicker';
import ConnectedCheckbox from './ConnectedCheckbox';
import SaveButton from './SaveButton';
import NavBar from './NavBar';

const Container = styled.div`
display: block;
`

const Break = styled.div`
clear: both
`

class Uppfoljningar extends React.Component {
  state = {
  }

  render() {
    const { patient, updatePatient } = this.props

    // Page logic
    let inteAktuellt = new Date('January 1, 2120 00:00:00')
    let date = (key) => new Date(patient[key])

    const telefonuppfoljning1_date_set =
        patient.telefonuppfoljning1 && date('telefonuppfoljning1') < inteAktuellt
    const telefonuppfoljning2_date_set =
        patient.telefonuppfoljning2 && date('telefonuppfoljning2') < inteAktuellt
    const telefonuppfoljning3_date_set =
        patient.telefonuppfoljning3 && date('telefonuppfoljning3') < inteAktuellt
    const telefonuppfoljning4_date_set =
        patient.telefonuppfoljning4 && date('telefonuppfoljning4') < inteAktuellt

    const extra_telefonuppfoljning_date_set =
        patient.extra_telefonuppfoljning && date('extra_telefonuppfoljning') < inteAktuellt
    const extra_telefonuppfoljning2_date_set =
        patient.extra_telefonuppfoljning2 && date('extra_telefonuppfoljning2') < inteAktuellt
    const extra_telefonuppfoljning3_date_set =
        patient.extra_telefonuppfoljning3 && date('extra_telefonuppfoljning3') < inteAktuellt
    const extra_telefonuppfoljning4_date_set =
        patient.extra_telefonuppfoljning4 && date('extra_telefonuppfoljning4') < inteAktuellt
    const extra_telefonuppfoljning5_date_set =
        patient.extra_telefonuppfoljning5 && date('extra_telefonuppfoljning5') < inteAktuellt

    return (
      <Container>
        <h1><Link to="/fidura-admin/">Patient . </Link>
          <Link to={`/fidura-admin/patient/${patient.id}`}>
            {patient.fornamn} {patient.efternamn}&nbsp;
            <span className="kodnummer">({patient.login} - {patient.kort_kodnummer})</span> .
        </Link></h1>

        <NavBar />

        <ConnectedDatePicker
          id={patient.id}
          name="aterbesok1"
          label="Återbesök 1, Toxkontroll"
          date={patient.aterbesok1}
          onSave={updatePatient}
          OBS="Om du ändrar det här datumet måste du manuellt ändra <b>Enkät 2 planerat</b> (en vecka innan Återbesök 1) och <b>Faecesprov 4 planerat</b> (4 dagar innan Återbesök 1)"
          timeSelect
          kanInteAktuellt
        />

        <ConnectedDatePicker
          id={patient.id}
          name="aterbesok2"
          label="Återbesök 2"
          date={patient.aterbesok2}
          onSave={updatePatient}
          OBS="Om du ändrar det här datumet måste du manuellt ändra <b>Enkät 3 planerat</b> (en vecka innan Återbesök 2), <b>Blodprov 4 planerat</b>, <b>Faecesprov 5 planerat</b> (4 dagar innan Återbesök 2)"
          timeSelect
          kanInteAktuellt
        />

        <hr />

        <ConnectedDatePicker
          inline
          id={patient.id}
          name="telefonuppfoljning1"
          label="Telefonuppföljning 1"
          date={patient.telefonuppfoljning1}
          onSave={updatePatient}
          kanInteAktuellt
        />

        <ConnectedTextInput
          inline
          hidden={!telefonuppfoljning1_date_set}
          textarea
          id={patient.id}
          name="telefonuppfoljning1_kommentar"
          label="Skrivfält"
          value={patient.telefonuppfoljning1_kommentar}
          onSave={updatePatient}
        />

        <ConnectedCheckbox
          inline
          hidden={!telefonuppfoljning1_date_set}
          id={patient.id}
          name="telefonuppfoljning1_genomford"
          label="Genomförd"
          checked={patient.telefonuppfoljning1_genomford}
          onSave={updatePatient}
        />

        <Break />

        <ConnectedDatePicker
          inline
          id={patient.id}
          name="telefonuppfoljning2"
          label="Telefonuppföljning 2"
          date={patient.telefonuppfoljning2}
          onSave={updatePatient}
          kanInteAktuellt
        />

        <ConnectedTextInput
          inline
          hidden={!telefonuppfoljning2_date_set}
          textarea
          id={patient.id}
          name="telefonuppfoljning2_kommentar"
          label="Skrivfält"
          value={patient.telefonuppfoljning2_kommentar}
          onSave={updatePatient}
        />

        <ConnectedCheckbox
          inline
          hidden={!telefonuppfoljning2_date_set}
          id={patient.id}
          name="telefonuppfoljning2_genomford"
          label="Genomförd"
          checked={patient.telefonuppfoljning2_genomford}
          onSave={updatePatient}
        />

        <Break />

        <ConnectedDatePicker
          inline
          id={patient.id}
          name="telefonuppfoljning3"
          label="Telefonuppföljning 3"
          date={patient.telefonuppfoljning3}
          onSave={updatePatient}
          kanInteAktuellt
        />

        <ConnectedTextInput
          inline
          hidden={!telefonuppfoljning3_date_set}
          textarea
          id={patient.id}
          name="telefonuppfoljning3_kommentar"
          label="Skrivfält"
          value={patient.telefonuppfoljning3_kommentar}
          onSave={updatePatient}
        />

        <ConnectedCheckbox
          inline
          hidden={!telefonuppfoljning3_date_set}
          id={patient.id}
          name="telefonuppfoljning3_genomford"
          label="Genomförd"
          checked={patient.telefonuppfoljning3_genomford}
          onSave={updatePatient}
        />

        <Break />

        <ConnectedDatePicker
          inline
          id={patient.id}
          name="telefonuppfoljning4"
          label="Telefonuppföljning 4"
          date={patient.telefonuppfoljning4}
          onSave={updatePatient}
          kanInteAktuellt
        />

        <ConnectedTextInput
          inline
          hidden={!telefonuppfoljning4_date_set}
          textarea
          id={patient.id}
          name="telefonuppfoljning4_kommentar"
          label="Skrivfält"
          value={patient.telefonuppfoljning4_kommentar}
          onSave={updatePatient}
        />

        <ConnectedCheckbox
          inline
          hidden={!telefonuppfoljning4_date_set}
          id={patient.id}
          name="telefonuppfoljning4_genomford"
          label="Genomförd"
          checked={patient.telefonuppfoljning4_genomford}
          onSave={updatePatient}
        />

        <Break />

        <ConnectedDatePicker
          inline
          id={patient.id}
          name="extra_telefonuppfoljning"
          label="Extra telefonuppföljning"
          date={patient.extra_telefonuppfoljning}
          onSave={updatePatient}
          kanInteAktuellt
        />

        <ConnectedTextInput
          inline
          hidden={!extra_telefonuppfoljning_date_set}
          textarea
          id={patient.id}
          name="extra_telefonuppfoljning_kommentar"
          label="Skrivfält"
          value={patient.extra_telefonuppfoljning_kommentar}
          onSave={updatePatient}
        />

        <ConnectedCheckbox
          inline
          hidden={!extra_telefonuppfoljning_date_set}
          id={patient.id}
          name="extra_telefonuppfoljning_genomford"
          label="Genomförd"
          checked={patient.extra_telefonuppfoljning_genomford}
          onSave={updatePatient}
        />

        <Break />

        { patient.extra_telefonuppfoljning &&
          <ConnectedDatePicker
            inline
            id={patient.id}
            name="extra_telefonuppfoljning2"
            label="Extra telefonuppföljning 2"
            date={patient.extra_telefonuppfoljning2}
            onSave={updatePatient}
          />
        }

        { patient.extra_telefonuppfoljning &&
          <ConnectedTextInput
            inline
            hidden={!extra_telefonuppfoljning2_date_set}
            textarea
            id={patient.id}
            name="extra_telefonuppfoljning2_kommentar"
            label="Skrivfält"
            value={patient.extra_telefonuppfoljning2_kommentar}
            onSave={updatePatient}
          />
        }

        { patient.extra_telefonuppfoljning &&
          <ConnectedCheckbox
            inline
            hidden={!extra_telefonuppfoljning2_date_set}
            id={patient.id}
            name="extra_telefonuppfoljning2_genomford"
            label="Genomförd"
            checked={patient.extra_telefonuppfoljning2_genomford}
            onSave={updatePatient}
          />
        }

        <Break />

        { patient.extra_telefonuppfoljning2 &&
          <ConnectedDatePicker
            inline
            id={patient.id}
            name="extra_telefonuppfoljning3"
            label="Extra telefonuppföljning 3"
            date={patient.extra_telefonuppfoljning3}
            onSave={updatePatient}
          />
        }

        { patient.extra_telefonuppfoljning2 &&
          <ConnectedTextInput
            inline
            hidden={!extra_telefonuppfoljning3_date_set}
            textarea
            id={patient.id}
            name="extra_telefonuppfoljning3_kommentar"
            label="Skrivfält"
            value={patient.extra_telefonuppfoljning3_kommentar}
            onSave={updatePatient}
          />
        }

        { patient.extra_telefonuppfoljning2 &&
          <ConnectedCheckbox
            inline
            hidden={!extra_telefonuppfoljning3_date_set}
            id={patient.id}
            name="extra_telefonuppfoljning3_genomford"
            label="Genomförd"
            checked={patient.extra_telefonuppfoljning3_genomford}
            onSave={updatePatient}
          />
        }

        <Break />

        { patient.extra_telefonuppfoljning3 &&
          <ConnectedDatePicker
            inline
            id={patient.id}
            name="extra_telefonuppfoljning4"
            label="Extra telefonuppföljning 4"
            date={patient.extra_telefonuppfoljning4}
            onSave={updatePatient}
          />
        }

        { patient.extra_telefonuppfoljning3 &&
          <ConnectedTextInput
            inline
            hidden={!extra_telefonuppfoljning4_date_set}
            textarea
            id={patient.id}
            name="extra_telefonuppfoljning4_kommentar"
            label="Skrivfält"
            value={patient.extra_telefonuppfoljning4_kommentar}
            onSave={updatePatient}
          />
        }

        { patient.extra_telefonuppfoljning3 &&
          <ConnectedCheckbox
            inline
            hidden={!extra_telefonuppfoljning4_date_set}
            id={patient.id}
            name="extra_telefonuppfoljning4_genomford"
            label="Genomförd"
            checked={patient.extra_telefonuppfoljning4_genomford}
            onSave={updatePatient}
          />
        }

        <Break />

        { patient.extra_telefonuppfoljning4 &&
          <ConnectedDatePicker
            inline
            id={patient.id}
            name="extra_telefonuppfoljning5"
            label="Extra telefonuppföljning 5"
            date={patient.extra_telefonuppfoljning5}
            onSave={updatePatient}
          />
        }

        { patient.extra_telefonuppfoljning4 &&
          <ConnectedTextInput
            inline
            hidden={!extra_telefonuppfoljning5_date_set}
            textarea
            id={patient.id}
            name="extra_telefonuppfoljning5_kommentar"
            label="Skrivfält"
            value={patient.extra_telefonuppfoljning5_kommentar}
            onSave={updatePatient}
          />
        }

        { patient.extra_telefonuppfoljning4 &&
          <ConnectedCheckbox
            inline
            hidden={!extra_telefonuppfoljning5_date_set}
            id={patient.id}
            name="extra_telefonuppfoljning5_genomford"
            label="Genomförd"
            checked={patient.extra_telefonuppfoljning5_genomford}
            onSave={updatePatient}
          />
        }

        <div>
          <Link to={`/fidura-admin/patient/${patient.id}`}>
            <button className="button marged">← Tillbaka</button>
          </Link>
          <SaveButton />
        </div>

      </Container>
    )
  }
}

export default Uppfoljningar;
