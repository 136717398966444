import React from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import ConnectedDatePicker from './ConnectedDatePicker';
import ConnectedCheckbox from './ConnectedCheckbox';
import SaveButton from './SaveButton';
import NavBar from './NavBar';

const Container = styled.div`
display: block;
`

const Warning = styled.div`
color: red;
margin-left: 10em;
`

const Note = styled.div`
color: #666;
padding: 2em;

`

class Mobilapp extends React.Component {
  state = {
  }

  render() {
    const { patient, updatePatient } = this.props

    return (
      <Container>
        <h1><Link to="/fidura-admin/">Patient . </Link>
          <Link to={`/fidura-admin/patient/${patient.id}`}>
            {patient.fornamn} {patient.efternamn}&nbsp;
            <span className="kodnummer">({patient.login} - {patient.kort_kodnummer})</span> .
        </Link></h1>

        <NavBar />

        <ConnectedCheckbox
          id={patient.id}
          name="skicka_ej_mobilapp_paminelser"
          label="Skicka ej sms-påminnelse mobilapp"
          checked={patient.skicka_ej_mobilapp_paminelser}
          onSave={updatePatient}
          long
        />

        <Note><b>OBS:</b> Påminnelser onsdagar (07.00) och söndagar (17.00) från Inklusionsmöte (högst 16 dagar innan strålstart) till högst 37 dagar efter strålslut.</Note>

        <Warning>* Datum ifylles automatiskt från mobilappen</Warning>
        <ConnectedDatePicker
          id={patient.id}
          disabled
          name="kost"
          label="Kost"
          starred
          date={patient.kost}
          onSave={updatePatient}
        />

        <ConnectedDatePicker
          id={patient.id}
          disabled
          name="toabesok_med_avforing"
          label="Toabesök med avföring"
          starred
          date={patient.toabesok_med_avforing}
          onSave={updatePatient}
        />

        <ConnectedDatePicker
          id={patient.id}
          disabled
          name="mediciner"
          label="Mediciner"
          starred
          date={patient.mediciner}
          onSave={updatePatient}
        />

        <ConnectedDatePicker
          id={patient.id}
          disabled
          name="lackage"
          label="Buksmärta"
          starred
          date={patient.lackage}
          onSave={updatePatient}
        />

        <ConnectedDatePicker
          id={patient.id}
          disabled
          name="gaser"
          label="Gaser"
          starred
          date={patient.gaser}
          onSave={updatePatient}
        />

        <ConnectedDatePicker
          id={patient.id}
          disabled
          name="kapslar"
          label="Kapslar"
          starred
          date={patient.kapslar}
          onSave={updatePatient}
        />

        <Link to={`/fidura-admin/patient/${patient.id}`}>
          <button className="button marged">← Tillbaka</button>
        </Link>
        <SaveButton />

      </Container>
    )
  }
}

export default Mobilapp;
