import React from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { ReactComponent as LoadingImg } from './loading.svg';
import DB from './DB.js';

const Container = styled.div`
display: block;
`

class NewPatient extends React.Component {
  state = {
    loading: false,
    personnummer: ''
  }

  onClick = () => {
    const { personnummer } = this.state
    const { onSave, history } = this.props

    this.setState({ loading: true }, () =>
        DB.createPatient(personnummer)
          .then((patient) => {
            onSave({ id: patient.id, personnummer: personnummer }, () =>
              history.push(`/fidura-admin/patient/${patient.id}`)
            )
          })
          .catch(err => {
            this.setState({ loading: false })
            alert('Personnummer redan registrerat eller ogiltigt personnummer')
            console.log(err)
          })
    )
  }

  onChange = (e) => {
    this.setState({ personnummer: e.target.value })
  }

  render() {
    const { personnummer, loading } = this.state

    return (
      <Container>
        <h1><Link to="/fidura-admin/">Patienter . </Link>Ny Patient</h1>

        <div>
        <label>Personnummer</label>
        <input
          type="text"
          name="personnummer"
          pattern="^[0-9]{8,10}$"
          value={personnummer}
          onChange={this.onChange}
        />
        </div>

        <button
          onClick={this.onClick}
          className="button marged">Registrera</button>

        { loading &&
          <LoadingImg style={{height: "1.5em", width: "3em", verticalAlign: "middle"}} />
        }


      </Container>
    )
  }
}

export default NewPatient;
