import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import styled from 'styled-components';

const Container = styled.div`
display: block;
`

const NotificationTitle = styled.h2`
margin-top: 2em;
margin-left: 0.5em;
`

const ReloadButton = styled.button`
vertical-align: middle;
`

const NotificationList = styled.ul`
list-style: circle;
`

const Paminelse = styled.li`
`

const Bevakning = styled.div`
& a {
  display: block;
  padding: 0.5em 1em;
  margin: 0.5em 1em;
  border: 1px solid #aaa;
  color: #333;
  text-decoration: none;
  border-radius: 3px;
  font-size: 1.2em;
  background: #eee;
}

& a:hover,
& a:focus,
& a:active,
& a.active {
  background: #ccc;
}

& a.active {
  font-weight: bold
}
`

class Bevakningar extends React.Component {
  state = {
  }

  render() {
    const { patients, reminders, notifications, appNotifications, updateNotifications } = this.props

    const bevakningar = reminders.reduce((acc, r) => {
      const category = r.page === "" ? "Extra bevakningar" :
        r.page === "uppfoljningar" ? "Uppföljningar" :
        r.page === "enkater" ? "Enkäter" :
        r.page === "prover" ? "Prover" : "Övrigt"

      return { ...acc, [category]: [...acc[category], r] }
    }, {
      "Extra bevakningar": [],
      "Uppföljningar": [],
      "Enkäter": [],
      "Prover": [],
      "Övrigt": [],
    })

    const toJSX = (r, k) => {
      const patient = patients.find(p => p.id === r.patient_id)
      return <Bevakning key={k}>
        <Link smooth
          to={`/fidura-admin/patient/${r.patient_id}${r.page ? '/' : ''}${r.page}#anchor-${r.field}`}>
          <span role="img" aria-label="bell">🔔 </span><b>{`${patient.fornamn} ${patient.efternamn}`}: </b>
          {r.message}
        </Link>
      </Bevakning>
    }

    const paminelser = notifications.map((n, k) =>
      <Paminelse key={k}>
        <Link to={`/fidura-admin/patient/${n[2]}#top`}>{n[0]}</Link> will receive the notification '{n[1]}'
      </Paminelse>
    )

    const mobilappPaminelser = appNotifications.map((n, k) =>
      <Paminelse key={k}>
        <Link to={`/fidura-admin/patient/${n[2]}#top`}>{n[0]}</Link>
      </Paminelse>
    )

    const now = new Date()

    return (
      <Container>
        <h1><Link to="/fidura-admin/">Patienter . </Link>Bevakningar</h1>

        { Object.keys(bevakningar).map(cat => {
          if (bevakningar[cat].length > 0) {
            return <>
              <h3>&nbsp;&nbsp;·&nbsp;{cat}&nbsp;·&nbsp;</h3>
              { bevakningar[cat].map(toJSX) }
            </>
          } else {
            return <></>
          }
        }) }

        <NotificationTitle>
          Kommande påminnelser till patienter
          &nbsp;<small>(blodprov och enkät klockan 10, avföringsprov klockan 7)</small>
          &nbsp;<ReloadButton title="Reload"
          onClick={() => updateNotifications()}>&#x21bb;</ReloadButton>
        </NotificationTitle>

        <NotificationList>
          { (notifications.length > 0 && paminelser) ||
              <Paminelse>No notifications</Paminelse>
          }
        </NotificationList>

        { now.getDay() === 2 && <>
          <NotificationTitle>
            Kommande mobilapp påminnelser till patienter
            &nbsp;<small>(imorgon klockan 10)</small>
            &nbsp;<ReloadButton title="Reload"
            onClick={() => updateNotifications()}>&#x21bb;</ReloadButton>
          </NotificationTitle>

          <NotificationList>
            { (appNotifications.length > 0 && mobilappPaminelser) ||
                <Paminelse>No notifications</Paminelse>
            }
          </NotificationList>
        </> }

        { (now.getDay() === 0 || now.getDay() === 6 || now.getDay() === 5) && <>
          <NotificationTitle>
            Kommande mobilapp påminnelser till patienter
            &nbsp;<small>(söndag klockan 18)</small>
            &nbsp;<ReloadButton title="Reload"
            onClick={() => updateNotifications()}>&#x21bb;</ReloadButton>
          </NotificationTitle>

          <NotificationList>
            { (appNotifications.length > 0 && mobilappPaminelser) ||
                <Paminelse>No notifications</Paminelse>
            }
          </NotificationList>
        </> }

        <Link to="/fidura-admin/"><button className="button marged">← Tillbaka</button></Link>
      </Container>
    )
  }
}

export default Bevakningar;
