import React from 'react';
import { ReactComponent as LoadingImg } from './loading.svg';

class SaveButton extends React.Component {
  state = {
    loading: false
  }

  onClick = () =>
    this.setState({
      loading: true
    }, () =>
      setTimeout(() =>
        this.setState({loading: false})
      , 800)
    )

  render() {
    const { loading } = this.state

    const classes = `button marged ${ loading ? 'active': ''}`

    const text = loading ? "Sparar": "Spara"

    const icon = loading ?
      <LoadingImg style={{height: "1.5em", width: "3em", verticalAlign: "middle"}} /> : <span />

    return <span>
      <button onClick={this.onClick} className={classes}>{text}</button>
      {icon}
    </span>
  }
}

export default SaveButton;
