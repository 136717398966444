import React from 'react';
import styled from 'styled-components';
import { ReactComponent as LoadingImg } from './loading.svg';
import DB from './DB.js';

const Container = styled.div`
display: block;
clear: both;
padding: 1em;
`

const Saved = styled.span`
color: green;
padding-left: 1em;

&.shown {
  visibility: visible;
  opacity: 1;
  transition: opacity 0s linear;
}

&.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
}
`

const Label = styled.label`
display: inline-block;
width: 150px;
text-align: right;
vertical-align: middle;
`

const Select = styled.select`
width: 267px;
font-size: 1.1em;
vertical-align: middle;
`

const ActionRow = styled.div`
text-align: left;
font-size: 80%;
margin-left: 170px;
margin-top: 5px;
`

const AndraButton = styled.a`
color: blue;
cursor: pointer;

&:hover {
  text-decoration: underline;
}

`

class ConnectedSelect extends React.Component {
  state = {
    value: '',
    loading: false,
    savedMessage: false,
    disabled: this.props.disabled || false
  }

  componentDidUpdate(prevProps) {
    const { value, id, name, onSave } = this.props

    if (value !== prevProps.value) {
      this.setState({ value: value }, () => {
        if (prevProps.value) {
          onSave(id, name, value)
        }
      })
    }
  }

  componentDidMount() {
    this.setState({
      value: this.props.value
    })
  }

  onChange = (e) => {
    let value = e.target.value
    const { id, name, onSave, options, boolean } = this.props

    if (boolean) {
      if (value === options[0].id) {
        value = true
      } else if (value === options[1].id) {
        value = false
      } else {
        value = null
      }
    }

    this.setState({ value: value, loading: true }, () =>
      DB.saveChanges(id, { [name]: value })
        .then((pat) =>
          this.setState({
            loading: false,
            savedMessage: Boolean(value)
          }, () => {
            onSave(pat, () =>
              setTimeout(() => {
                this.setState({savedMessage: false})
              }, 1000)
            )
          })
        )
        .catch(err => console.log(err))
    )
    
  }

  render() {
    const { label, name, title, autoFocus, options, hidden, inline,
      boolean, changeMsg } = this.props
    const { loading, savedMessage, disabled } = this.state
    const hash = window.location.hash.substr(1)
    let value = this.state.value

    let classes = ""
    if (savedMessage) classes += " valid"
    if (loading) classes += " loading"

    if (boolean) {
      if (value === true) {
        value = options[0].id
      } else if (value === false) {
        value = options[1].id
      } else {
        value = ''
      }
    }

    const display = hidden ? 'none' : inline ? 'inline-block' : 'block'
    const labelWidth = inline && label === "" ? '0' : '150px'
    const paddingLeft = inline && label === "" ? '0' : '1em'
    const paddingRight = inline && label !== "" ? '0' : '1em'


    const children = options.map(o =>
      <option key={o.id} value={o.id}>{o.name}</option>
    )

    return (
      <Container id={`anchor-${name}`}
        className={(hash === `anchor-${name}`) ? 'highlighted' : null}
        style={{display, paddingLeft, paddingRight }}>
        <Label style={{width: labelWidth }}>{label}</Label>
        <Select
          value={value || ''}
          onChange={this.onChange}
          title={title}
          autoFocus={autoFocus}
          disabled={disabled}
          className={classes}
        >
          <option value=''></option>
          {children}
        </Select>

        { changeMsg && disabled &&
          <ActionRow>
            <AndraButton
              onClick={(e) =>
                this.setState({disabled: !window.confirm(changeMsg)})
              }> Ändra
            </AndraButton>
          </ActionRow>
        }

        { loading && (
          <LoadingImg style={{height: "1.5em", width: "3em", verticalAlign: "middle"}}/>
        )}

        <Saved className={ savedMessage ? "shown" : "hidden" }>Sparats</Saved>

      </Container>
    )
  }
}

export default ConnectedSelect;
