import React from 'react';
import styled from 'styled-components';
import { ReactComponent as LoadingImg } from './loading.svg';
import DB from './DB.js';
import Helpers from './Helpers.js';

const Container = styled.div`
display: block;
vertical-align: middle;
clear: both;
padding: 1em;
`

const Saved = styled.span`
color: green;
padding-left: 1em;

&.shown {
  visibility: visible;
  opacity: 1;
  transition: opacity 0s linear;
}

&.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
}
`

const Label = styled.label`
display: inline-block;
width: 150px;
text-align: right;
vertical-align: middle;
`

const Input = styled.input`
width: 230px;
font-size: 1.1em;
vertical-align: middle;
`

const TextArea = styled.textarea`
width: 236px;
min-height: 5.5em;
font-size: 1.1em;
resize: vertical;
overflow: auto;
vertical-align: middle;
`

class ConnectedTextInput extends React.Component {
  state = {
    value: '',
    loading: false,
    changed: false,
    saved: false,
    savedMessage: false,
    invalid: false
  }

  componentDidUpdate(prevProps) {
    const { value, id, name, personnummer, pattern, onSave } = this.props

    if (value !== prevProps.value) {

      let valid = true
      let val = personnummer && value.length === 12 ?
        value.substring(2, 12) : value

      valid = personnummer ?
        Helpers.personnummerIsValid(val) :
        new RegExp(pattern).test(val)

      this.setState({ value: val, valid: valid  }, () => {
        if (prevProps.value) {
          onSave(id, name, val)
        }
      })
    }
  }

  componentDidMount() {
    const { pattern, value, personnummer } = this.props
    let valid = true
    let val = value

    if (value) {
      val = personnummer && value.length === 12 ?
        value.substring(2, 12) : value

      valid = personnummer ?
        Helpers.personnummerIsValid(val) :
        new RegExp(pattern).test(val)
    }

    this.setState({
      value: val,
      invalid: !valid
    })
  }

  onBlur = () => {
    const { value, changed } = this.state
    const { id, name, pattern, onSave, personnummer } = this.props

    let valid = true
    if (value) {
      valid = personnummer ?
        Helpers.personnummerIsValid(value) :
        new RegExp(pattern).test(value)
    }


    if (changed) {
      this.setState({ loading: true }, () =>
        DB.saveChanges(id, { [name]: value })
          .then((pat) =>
            this.setState({
              loading: false,
              saved: Boolean(value),
              savedMessage: valid && Boolean(value),
              changed: false,
              invalid: !valid
            }, () => {
              onSave(pat, () =>
                setTimeout(() => {
                  this.setState({savedMessage: false})
                }, 1000)
              )
            })
          )
          .catch(err => console.log(err))
      )


    }
  }

  onChange = (e) => {
    this.setState({
      value: e.target.value,
      changed: true
    })
  }

  onEnter = (e) => {
    if(e.which === 13) {
      this.onBlur()
    }
  }

  onFocus = (e) => {
    let temp_value = e.target.value
    e.target.value = ''
    e.target.value = temp_value
  }

  render() {
    const { label, name, password, pattern, title, autoFocus, disabled, inline,
      hidden, textarea, readOnly } = this.props
    const { loading, saved, savedMessage, invalid, value } = this.state
    const hash = window.location.hash.substr(1)

    let classes = ""
    if (saved) classes += " valid"
    if (invalid) classes += " invalid"
    if (loading) classes += " loading"

    const display = hidden ? 'none' : inline ? 'inline-block' : 'block'
    const labelWidth = inline && label === "" ? '0' : '150px'
    const paddingLeft = inline && label === "" ? '0' : '1em'
    const paddingRight = inline && label !== "" ? '0' : '1em'

    return (
      <Container id={`anchor-${name}`}
        style={{ display, paddingLeft, paddingRight }}
        className={(hash === `anchor-${name}`) ? 'highlighted' : null}
      >
        <Label style={{width: labelWidth}}>{label}</Label>
        { !textarea &&
          <Input
            type={password ? 'password': 'text'}
            onBlur={this.onBlur}
            value={value || ''}
            onChange={this.onChange}
            onKeyPress={this.onEnter}
            onFocus={this.onFocus}
            pattern={pattern}
            title={title}
            className={classes}
            autoFocus={autoFocus}
            disabled={disabled}
            readOnly={readOnly}
          />
        }

        { textarea &&
          <TextArea
            onBlur={this.onBlur}
            onChange={this.onChange}
            onFocus={this.onFocus}
            pattern={pattern}
            title={title}
            className={classes}
            autoFocus={autoFocus}
            disabled={disabled}
            value={value || ''}
          />
        }

        { loading && (
          <LoadingImg style={{height: "1.5em", width: "3em", verticalAlign: "middle"}}/>
        )}

        <Saved className={ savedMessage ? "shown" : "hidden" }>Sparats</Saved>

      </Container>
    )
  }
}

export default ConnectedTextInput;
