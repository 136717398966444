import Cookies from 'js-cookie';

const APIEndpoint = process.env.NODE_ENV !== 'production' ?
  //'http://localhost:5000/fidura/api/v1' :
  'https://dev.eftercancern.se/fidura/api/v1' :
  'https://www.eftercancern.se/fidura/api/v1'

const isLocal = ["localhost", "127.0.0.1"].includes(window.location.hostname)

const DB = {

  set(key, value, expires_in = 86400) {
    const params = {
      expires: expires_in/86400,
      path: window.location.href.indexOf("fidura-admin") > -1 ? '/fidura-admin' : "/",
      secure: true
    }

    return isLocal ?
      window.localStorage.setItem(key, value) :
      Cookies.set(key, value, params)
  },

  get(key) {
    return isLocal ?
      window.localStorage.getItem(key) :
      Cookies.get(key)
  },

  remove(key) {
    return isLocal ?
      window.localStorage.removeItem(key) :
      Cookies.remove(key)
  },

  login(username, password) {
    let region = window.localStorage.getItem('region') || "goteborg"
    let headers = new Headers()
    headers.set("Authorization", "Basic " + btoa(username + ':' + password))

    return fetch(`${APIEndpoint}/admin/token/${region}`, {
      method: 'GET',
      headers: headers
    }).then(response => response.json())
      .then(data => {
        this.set('username', data.username, data.expires_in)
        this.set('access_token', data.access_token, data.expires_in)

        return data.access_token
      })
  },

  getUser() {
    return this.get('username')
  },

  getAuthHeaders() {
    let headers = new Headers()

    headers.set("Authorization", "Bearer " + this.get('access_token'))
  
    return headers
  },
  
  getPatients() {
    return fetch(`${APIEndpoint}/patient/list`, {
        method: 'GET',
        headers: this.getAuthHeaders()
      }).then(response => response.json())
  },

  getReminders() {
    return fetch(`${APIEndpoint}/reminder/list`, {
        method: 'GET',
        headers: this.getAuthHeaders()
      }).then(response => response.json())
  },

  getNotifications1() {
    return fetch(`${APIEndpoint}/notification/list1`, {
        method: 'GET',
        headers: this.getAuthHeaders()
      }).then(response => response.json())
  },

  getNotifications2() {
    return fetch(`${APIEndpoint}/notification/list2`, {
        method: 'GET',
        headers: this.getAuthHeaders()
      }).then(response => response.json())
  },

  getAppNotifications1() {
    const now = new Date()

    return fetch(`${APIEndpoint}/notification/app1`, {
      method: 'GET',
      headers: this.getAuthHeaders()
    }).then(response => now.getDay() === 2 ? response.json() : [])
  },

  getAppNotifications2() {
    const now = new Date()

    return fetch(`${APIEndpoint}/notification/app2`, {
      method: 'GET',
      headers: this.getAuthHeaders()
    }).then(response => now.getDay() === 0 || now.getDay() === 6 || now.getDay() === 5 ? response.json() : [])
  },

  logout() {
    return fetch(`${APIEndpoint}/logout`, {
        method: 'DELETE',
        headers: this.getAuthHeaders()
    }).then((response) => {
      this.remove('username')
      this.remove('access_token')

      return response
    })
  },

  compute(patientID, data) {
    let headers = this.getAuthHeaders()

    return fetch(`${APIEndpoint}/patient/${patientID}`, {
        method: 'GET',
        headers: headers
    })
      .then(response => response.json())
      .then(patient => {
        let mods = {}

        for (var key in data) {
          switch (key) {

            case 'personnummer':
              const personnummer = data[key].length === 12 ?
                data[key].substring(2, 12) : data[key]
              const bday = data[key].length === 12 ?
                data[key].substr(0, 8) :
                `19${data[key]}`.substr(0, 8)
              const birthdate = new Date(
                bday.substr(0, 4),
                parseInt(bday.substr(4, 2)) - 1,
                bday.substr(6, 2))
              const age = patient.alder ? patient.alder :
                Math.abs(new Date(
                  Date.now() - birthdate.getTime()
                ).getUTCFullYear() - 1970).toString()

              mods = {...mods, personnummer, 'alder': age }
              break;

            case 'aterbesok1':
              if (data[key] > new Date('January 1, 2110 00:00:00')) {
                mods = {...mods, [key]: data[key] }
                break;
              }

              let fp4Date = new Date(data[key])
              fp4Date.setDate(fp4Date.getDate() - 4)
              let e2Date = new Date(data[key])
              e2Date.setDate(e2Date.getDate() - 7)

              mods = {...mods, [key]: data[key],
                ...patient.blodprov3_planerat ? {} : {'blodprov3_planerat': data[key]},
                ...patient.faecesprov4_planerat ? {} : {'faecesprov4_planerat': fp4Date},
                ...patient.enkat2_planerat ? {} : {'enkat2_planerat': e2Date}
              }
              break;

            case 'aterbesok2':
              if (data[key] > new Date('January 1, 2110 00:00:00')) {
                mods = {...mods, [key]: data[key] }
                break;
              }

              let fp5Date = new Date(data[key])
              fp5Date.setDate(fp5Date.getDate() - 4)
              let e3Date = new Date(data[key])
              e3Date.setDate(e3Date.getDate() - 7)

              mods = {...mods, [key]: data[key],
                ...patient.blodprov4_planerat ? {} : {'blodprov4_planerat': data[key]},
                ...patient.faecesprov5_planerat ? {} : {'faecesprov5_planerat': fp5Date},
                ...patient.enkat3_planerat ? {} : {'enkat3_planerat': e3Date}
              }
              break;

            case 'inklusionsmote':
              if (data[key] > new Date('January 1, 2110 00:00:00')) {
                mods = {...mods, [key]: data[key] }
                break;
              }

              let fp1Date = new Date(data[key])
              fp1Date.setDate(fp1Date.getDate() + 1)

              mods = {...mods, [key]: data[key],
                ...patient.faecesprov1_planerat ? {} : {'faecesprov1_planerat': fp1Date},
                ...patient.enkat1_planerat ? {} : {'enkat1_planerat': data[key]}
              }
              break;

            case 'stralstart':
              let fp2Date = new Date(data[key])
              fp2Date.setDate(fp2Date.getDate() - 2)

              mods = {...mods, [key]: data[key],
                ...patient.faecesprov2_planerat ? {} : {'faecesprov2_planerat': fp2Date}
              }
              break;

            case 'stralslut':
              let fp3Date = new Date(data[key])
              fp3Date.setDate(fp3Date.getDate() - 6)

              let e3pDate = new Date(data[key])
              e3pDate.setDate(e3pDate.getDate() + 334)

              mods = {...mods, [key]: data[key],
                ...patient.faecesprov3_planerat ? {} : {'faecesprov3_planerat': fp3Date},
                ...patient.enkat3_planerat ? {} : {'enkat3_planerat': e3pDate}
              }
              break;

            case 'planerat_stralslut':
              if (data[key] > new Date('January 1, 2110 00:00:00') || patient.aterbesok1 || patient.faecesprov4_planerat) {
                mods = {...mods, [key]: data[key] }
                break;
              }

              let fp4Date2 = new Date(data[key])
              fp4Date2.setDate(fp4Date2.getDate() + 28)

              let fp3Date2 = new Date(data[key])
              fp3Date2.setDate(fp3Date2.getDate() - 3)

              let e2Date2 = new Date(data[key])
              e2Date2.setDate(e2Date2.getDate() + 21)

              mods = {...mods, [key]: data[key],
                ...patient.faecesprov3_planerat ? {} : {'faecesprov3_planerat': fp3Date2},
                ...patient.faecesprov4_planerat ? {} : {'faecesprov4_planerat': fp4Date2},
                ...patient.enkat2_planerat ? {} : {'enkat2_planerat': e2Date2}
              }
              break;

            case 'antal_pasar1':
            case 'antal_pasar2':
            case 'antal_pasar3':
            case 'antal_pasar4':
            case 'antal_pasar5':
              let totalt = 0
              for (const pasar of ['antal_pasar1', 'antal_pasar2', 'antal_pasar3',
                'antal_pasar4', 'antal_pasar5']) {

                if (key === pasar) totalt += parseFloat(data[pasar] ? data[pasar] : '0')
                else if (patient[pasar]) totalt += parseFloat(patient[pasar])
              }

              mods = {...mods, [key]: data[key], totalt_forpackningar: totalt.toString() }
              break;


            default:
              mods = {...mods, [key]: data[key] }
          }
        }

        return mods
      })
  },

  saveChanges(patientID, data) {
    let headers = this.getAuthHeaders()
    headers.set('Content-Type', 'application/json')

    return this.compute(patientID, data).then(mods =>
      fetch(`${APIEndpoint}/patient/${patientID}`, {
        method: 'PUT',
        body: JSON.stringify(mods),
        headers: headers
      }).then(response => response.json())
    )

  },

  createPatient(personnummer) {
    let headers = this.getAuthHeaders()
    headers.set('Content-Type', 'application/json')

    return fetch(`${APIEndpoint}/patients`, {
        method: 'POST',
        body: JSON.stringify({personnummer: personnummer}),
        headers: headers
      }).then(response => response.json())
  },

  deletePatient(patientID) {
    return fetch(`${APIEndpoint}/patient/${patientID}`, {
        method: 'DELETE',
        headers: this.getAuthHeaders()
      })
  }

}

export default DB;
