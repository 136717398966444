  // ES5 function
var personnummerIsValid = (input) => {
  // Check valid length & form
  if (!input) { return false; }

  if (input.indexOf('-') === -1) {
      if (input.length === 10) {
          input = input.slice(0, 6) + "-" + input.slice(6);
      } else {
          input = input.slice(0, 8) + "-" + input.slice(8);
      }
  }
  if (!input.match(/^(\d{2})(\d{2})(\d{2})-(\d{4})|(\d{4})(\d{2})(\d{2})-(\d{4})$/)) { return false };

  // Clean input
  input = input.replace('-', '');

  if (input.length === 10) {
      if (input.charAt(0) === '0') {
        input = '20' + input
      } else {
        input = '19' + input
      }
  }

  var numbers = input.match(/^(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)$/);
  var checkSum = 0;

  if (!isDate(input.substring(0,4),input.substring(4,6),input.substring(6,8))) {
      return false;
  }

  if (numbers === null) { return false; }

  var n;
  for (var i = 3; i <= 12; i++)
  {
      n=parseInt(numbers[i]);
      if (i % 2 === 0) {
          checkSum+=n;
      } else {
          checkSum+=(n*2)%9+Math.floor(n/9)*9
      }
  }

  if (checkSum%10===0) { return true;}
  return false;
}

var getYear = (y) => { return (y < 1000) ? y + 1900 : y; }

var isDate = (year, month, day) => {
  month = month - 1; // 0-11 in JavaScript
  var tmpDate = new Date(year,month,day);
  if ( (getYear(tmpDate.getYear()) === parseInt(year)) &&
  (parseInt(month) === tmpDate.getMonth()) &&
  (parseInt(day) === tmpDate.getDate()) )
      return true;
  else
      return false;
}

export default { personnummerIsValid, isDate };
